import Unit from "./Unit";
import React, {useEffect, useState} from "react";
import {Form, Table, Row, Col} from "antd";

import {calculateSummary, loadUnits} from "../../RosterConnector";
import {notificationFailure} from "../../RosterNotifications";

const Units = ({form, setSummary, selectedFaction, units}) => {

    const [selectionType, setSelectionType] = useState('checkbox');
    const [loading, setLoading] = useState(false)

    const [selectedUnits1, setSelectedUnits1] = useState([]);
    const [selectedUnits2, setSelectedUnits2] = useState([]);
    const [selectedUnits3, setSelectedUnits3] = useState([]);
    const [selectedUnits4, setSelectedUnits4] = useState([]);

    useEffect(() => {
        setSelectedUnits1(units.filter(unit => unit.selected).map(unit => unit.key))
        setSelectedUnits2(units.filter(unit => unit.selected).map(unit => unit.key))
        setSelectedUnits3(units.filter(unit => unit.selected).map(unit => unit.key))
        setSelectedUnits4(units.filter(unit => unit.selected).map(unit => unit.key))

        onUnitsChange()
    }, [units])

    useEffect(() => {
        onUnitsChange()
    }, [selectedUnits1, selectedUnits2, selectedUnits3, selectedUnits4])

    const onUnitsChange = () => {
        if (units !== undefined && units !== null) {
            form.setFieldsValue({units: units});

            calculateSummary(units, selectedFaction)
                .then(response => {
                    if (response.valid) {
                        setSummary(response.body.summary)
                    } else {
                        notificationFailure(response.errors[0].error)
                    }
                })
        }
    }

    const unitsColumns = [{
        title: 'Unit', dataIndex: 'unit', key: 'unit',
        render: (_, unit) => (
            <>
                <Unit unit={unit} onUnitsChange={onUnitsChange}/>
            </>
        )
    }];

    const rowSelection1 = {
        selectedRowKeys: selectedUnits1,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedUnits1(selectedRowKeys)
            selectedRows.forEach(selectedRow => {
                selectedRow.selected = true
            })
            onUnitsChange()
        }
    };

    const rowSelection2 = {
        selectedRowKeys: selectedUnits2,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedUnits2(selectedRowKeys)
            selectedRows.forEach(selectedRow => {
                selectedRow.selected = true
            })
            onUnitsChange()
        }
    };

    const rowSelection3 = {
        selectedRowKeys: selectedUnits3,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedUnits3(selectedRowKeys)
            selectedRows.forEach(selectedRow => {
                selectedRow.selected = true
            })
            onUnitsChange()
        }
    };

    const rowSelection4 = {
        selectedRowKeys: selectedUnits4,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedUnits4(selectedRowKeys)
            selectedRows.forEach(selectedRow => {
                selectedRow.selected = true
            })
            onUnitsChange()
        }
    };

    return <>
        <Form.Item id={"form-space-formitem"} name={"units"} style={{width: '100%'}}/>
        {units &&
            <>
                <Row justify="space-around">
                    <Col span={10}>
                        <p className="rosterEditSubTitles">Epic Hero</p>
                        <Table
                            columns={unitsColumns}
                            dataSource={units.filter(unit => unit.keywords !== null && unit.keywords.includes("EPIC_HERO"))}
                            showHeader={false}
                            pagination={false}
                            rowSelection={{
                                type: selectionType,
                                ...rowSelection1
                            }}
                            className="rosterEditTable"
                        />

                        <div className="columnItemSpacing"></div>

                        <p className="rosterEditSubTitles">Character</p>
                        <Table
                            columns={unitsColumns}
                            dataSource={units.filter(unit => unit.keywords !== null && !unit.keywords.includes("EPIC_HERO") && unit.keywords.includes("CHARACTER"))}
                            showHeader={false}
                            pagination={false}
                            rowSelection={{
                                type: selectionType,
                                ...rowSelection2
                            }}
                            className="rosterEditTable"
                        />
                    </Col>

                    <Col span={10}>
                        <p className="rosterEditSubTitles">Battleline</p>
                        <Table
                            columns={unitsColumns}
                            dataSource={units.filter(unit => unit.keywords !== null && !unit.keywords.includes("EPIC_HERO") && !unit.keywords.includes("CHARACTER") && unit.keywords.includes("BATTLELINE"))}
                            showHeader={false}
                            pagination={false}
                            rowSelection={{
                                type: selectionType,
                                ...rowSelection3
                            }}
                            className="rosterEditTable"
                        />

                        <div className="columnItemSpacing"></div>

                        <p className="rosterEditSubTitles">Other</p>
                        <Table
                            columns={unitsColumns}
                            dataSource={units.filter(unit => (unit.keywords === null) || (!unit.keywords.includes("EPIC_HERO") && !unit.keywords.includes("CHARACTER") && !unit.keywords.includes("BATTLELINE")))}
                            showHeader={false}
                            pagination={false}
                            rowSelection={{
                                type: selectionType,
                                ...rowSelection4
                            }}
                            className="rosterEditTable"
                        />
                    </Col>
                </Row>
            </>
        }
    </>
}

export default Units;