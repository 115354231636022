import React, {useEffect, useState} from "react";
import {Cascader, InputNumber, Space, Table, Tag, Checkbox, Select, Divider, Typography, Button} from 'antd';
import ModelsTableView from "./ModelsTableView";
import Leader from "./Leader";

const {Text, Link} = Typography;

const Weapons = ({model}) => {
    return (<>
            <Text type="secondary" style={{color: "silver"}}>{model.weapons
                .filter(weapon => weapon.selected === true)
                .map(weapon => weapon.name)
                .join(", ")}</Text>
        </>
    );
};

export default Weapons;






