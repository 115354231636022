import React, {useEffect, useState} from 'react';
import {Form, Select, Space, Spin, Steps, Tag} from 'antd';
import {LikeFilled, LikeOutlined} from "@ant-design/icons";
import {doRestCall} from "../../../AppUtils";
import TournamentTag from "./TournamentTag";

const TournamentTags = ({tournamentId, participantId}) => {

    const [tags, setTags] = useState([])

    function vote(id) {
        doRestCall('/tournament/tag/' + tournamentId + '/' + id + '/' + participantId + '/vote', 'put', null, null,
            (response) => { setTags(response) })
    }

    function unvote(id) {
        doRestCall('/tournament/tag/' + tournamentId + '/' + id + '/' + participantId + '/unvote', 'put', null, null,
            (response) => { setTags(response) })
    }

    useEffect(() => {
        doRestCall('/tournament/tags/' + tournamentId + '/' + participantId, 'get', null, null,
            (response) => {  setTags(response) })
    }, [])

    return <>
        <Space direction={"horizontal"}  >
            {tags?.map(tag => <TournamentTag
                key={tag.key}
                id={tag.id}
                label={tag.label}
                voted={tag.voted}
                count={tag.count}
                vote={vote}
                unvote={unvote}
            /> )}
        </Space>
    </>
}

export default TournamentTags;