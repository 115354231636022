import axios from "axios";
import {getToken} from "../../user/auth/Auth";
import {restRoute} from "../../AppUtils";




export function newsWC() {
    return axios
        .get(restRoute() + '/news', {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
