import axios from "axios";
import {getToken} from "../../user/auth/Auth";
import {restRoute} from "../../AppUtils";

export function codexFactions() {
    return axios
        .get(restRoute() + '/codex/factions', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function loadUnit(faction, unit) {
    return axios
        .get(restRoute() + '/codex/unit', {
            params: {
                faction: faction,
                name: unit
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function createCard(card) {
    return axios
        .post(restRoute() + '/crusade/card/create', card, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function calculateUnitPrice(unit) {
    return axios
        .post(restRoute() + '/crusade/card/calulcateunitprice', unit, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function viewCard(forceId, cardId) {
    return axios
        .get(restRoute() + '/crusade/force/' + forceId + '/card/' + cardId + '/view', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function updateName(forceId, cardId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/card/update/name', {}, {
            params: {
                forceId: forceId,
                cardId: cardId,
                name: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });
}

export function updateUnit(forceId, cardId, unit, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/card/update/unit', unit, {
            params: {
                forceId: forceId,
                cardId: cardId,
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });
}

export function updateFaction(forceId, cardId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/card/update/faction', {}, {
            params: {
                forceId: forceId,
                cardId: cardId,
                faction: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });
}


export function updateKeywords (forceId, cardId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/card/update/keywords', {}, {
            params: {
                forceId: forceId,
                cardId: cardId,
                keywords: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });

}
export function updateEquipment (forceId, cardId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/card/update/equipments', {}, {
            params: {
                forceId: forceId,
                cardId: cardId,
                equipments: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });

}
export function updateEnhancements (forceId, cardId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/card/update/enhancements', {}, {
            params: {
                forceId: forceId,
                cardId: cardId,
                enhancements: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });


}
export function updatePoints (forceId, cardId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/card/update/points', {}, {
            params: {
                forceId: forceId,
                cardId: cardId,
                points: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });


}
export function updateCrusadePoints (forceId, cardId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/card/update/crusadepoints', {}, {
            params: {
                forceId: forceId,
                cardId: cardId,
                crusadePoints: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });


}
export function updateExperiencePoints (forceId, cardId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/card/update/experiencepoints', {}, {
            params: {
                forceId: forceId,
                cardId: cardId,
                experiencePoints: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });


}
export function updateRank (forceId, cardId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/card/update/rank', {}, {
            params: {
                forceId: forceId,
                cardId: cardId,
                rank: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });


}
export function updateBattlesPlayed (forceId, cardId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/card/update/battlesplayed', {}, {
            params: {
                forceId: forceId,
                cardId: cardId,
                battlesPlayed: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });

}
export function updateBattlesSurvived (forceId, cardId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/card/update/battlessurvived', {}, {
            params: {
                forceId: forceId,
                cardId: cardId,
                battlesSurvived: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });


}
export function updateEnemyUnitsDestroyed (forceId, cardId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/card/update/enemyunitsdestroyed', {}, {
            params: {
                forceId: forceId,
                cardId: cardId,
                enemyUnitsDestroyed: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });


}
export function updateBattleHonors (forceId, cardId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/card/update/battlehonors', {}, {
            params: {
                forceId: forceId,
                cardId: cardId,
                battleHonors: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });


}
export function updateBattleScars (forceId, cardId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/card/update/battlescars', {}, {
            params: {
                forceId: forceId,
                cardId: cardId,
                battleScars: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });

}