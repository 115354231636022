import React, {useEffect, useState} from "react";
import {Button, Form, Input, Space, Row, Col, Select} from "antd";
import {useNavigate} from "react-router-dom";
import './styles.css'
import {GoogleAnalyticsPageView} from "../../../GoogleAnalytics";
import {Auth} from "../../../user/auth/Auth";


const CrusadeRostersAll = () => {

    GoogleAnalyticsPageView("/crusade/rosters/all", "Crusade Rosters All")

    const [form] = Form.useForm();
    const navigate = useNavigate();


    return (
        <>
            <Auth/>


            <p className="rosterCreateTitle">All Crusade Rosters</p>

        </>
    );
};

export default CrusadeRostersAll;

