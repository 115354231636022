import React, {useEffect, useState} from 'react';
import {Form, Select, Spin} from 'antd';
import {doRestCall} from "../../../AppUtils";
import {useNavigate} from "react-router-dom";

const Universes = ({setSelectedUniverse: setSelectedUniverse}) => {
    const [universeOptions, setUniverseOptions] = useState([])
    const [universes, setUniverses] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (universes !== undefined && universes !== null) {
            let temp = []
            for (let i = 0; i < universes.length; i++) {
                temp[i] = {
                    value: universes[i],
                    label: universes[i],
                }
            }
            setUniverseOptions(temp)
        }
    }, [universes]);

    useEffect(() => {
        setLoading(true)
        doRestCall( '/codex/universes', 'get', null, null,
            (response) => setUniverses(response.body),
            null,
            () => setLoading(false))
    }, []);

    const onUniverseChange = (value) => {
        setSelectedUniverse(value)
    };

    return <>
        <Spin spinning={loading}>
            <Select
                placeholder="Select universe"
                onChange={onUniverseChange}
                options={universeOptions}
                style={{width: 200}}
            />
        </Spin>
    </>
}

export default Universes;