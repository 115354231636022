import React, {useEffect, useState} from "react";
import {
    Button, Checkbox, DatePicker, Divider, Form, Input, Select, Space, Table
} from "antd";
import {usersAll} from "../../user/UserConnector";
import {useNavigate, useParams} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import moment from "moment/moment";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";

import Universes from "./components/Universes";
import Participants from "./components/Participants";
import {doRestCall} from "../../AppUtils";
const { TextArea } = Input;


const GameEdit = () => {

    GoogleAnalyticsPageView("/game/edit", "Game Edit")

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const [usersLoading, setUsersLoading] = useState(true)
    const [users, setUsers] = useState({});
    const [gameSettings, setGameSettings] = useState([]);
    const [selectedUniverse, setSelectedUniverse] = useState()

    function navigateBack() {
        navigate("/game/view/" + gameId)
    }

    let {gameId} = useParams();

    const [loading, setLoading] = useState(true)
    const [game, setGame] = useState({});

    useEffect(() => {
        form.setFieldsValue({universe: selectedUniverse});
    }, [selectedUniverse])

    useEffect(() => {
        load()
        loadUsersEffect()
    }, [])

    useEffect(() => {
        console.log(game)
        form.setFieldsValue({
            id: game.id,
            name: game.name,
            gameMasterId: game.gameMasterId,
            notes: game.notes,
            createdDateTime: game.createdDateTime,
        })

        game.dateTime && form.setFieldsValue({
            dateTime: moment(game.dateTime)
        })

        setGameSettings(game.gameSettings)

    }, [game])

    function onGameViewSuccess(response) {
        setGame(response.body)
        setLoading(false)
    }

    const load = () => {
        doRestCall( '/game/view/' + gameId, 'get', null, null, onGameViewSuccess)
    }

    const loadUsersEffect = () => {
        usersAll()
            .then(response => {
                for (let i = 0; i < response.body.length; i++) {
                    response.body[i] = {
                        id: response.body[i].id,
                        name: response.body[i].username,
                        value: response.body[i].id,
                        label: response.body[i].username,
                    }
                }

                setUsers(response.body)
                setUsersLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    function onSuccessUpdate(response) {
        if (response.valid) {
            navigate('/game/view/' + response.body.id)
        }
    }

    function onFinish(game) {
        doRestCall( '/game/update', 'put', null, game, onSuccessUpdate )
    }

    const filterGameMasters = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (<>
        <Auth/>

        <h2 className="gameEditTitle"> Edit {game.name}</h2>

        <Form id={"form"}
              onFinish={onFinish}
              form={form}
              style={{width: '100%'}}
              className="gameEditForm"
              layout="horizontal"
        >

            <Form.Item className="gameEditLabel" name="id" hidden="true">
                <Input className="gameEditInput" />
            </Form.Item>

            <Form.Item className="gameEditLabel" name="createdDateTime" hidden="true">
                <Input className="gameEditInput" />
            </Form.Item>

            <Form.Item
                className="gameEditLabel"
                label="Name"
                name="name"
                rules={[
                    {
                        required: true,
                        message: 'Please input name!',
                    },
                ]}>
                <Input className="gameEditInput"/>
            </Form.Item>

            <Form.Item
                className="gameEditLabel"
                label="Date"
                name="dateTime"
            >
                <DatePicker className="gameEditDate"/>
            </Form.Item>

            <Form.Item
                className="gameEditLabel"
                label="Universe"
                name="universe"
                style={{width: '8rem', justifyContent: 'start', display: 'flex'}}
            >
                <Universes setSelectedUniverse={setSelectedUniverse} preSelectedUniverse={game.universe}   />
            </Form.Item>

            <Form.Item
                className="gameEditLabel" label="Notes" name="notes" >
                <TextArea rows={6} maxLength={1000} />
            </Form.Item>

            {!usersLoading &&
                <Form.Item
                    label="Game Master" name="gameMasterId">
                    <Select options={users}
                            className="gameEditSelector"
                            showSearch
                            filterOption={filterGameMasters}/>
                </Form.Item>
            }

            <Participants form={form} participants={game.players} selectedUniverse={selectedUniverse} />

            <Space className="gameEditButtons" direction="horizontal">
                <Button onClick={navigateBack}>Back</Button>
                <Button type="primary" htmlType="submit" data-cy="submit" >
                    Submit
                </Button>
            </Space>

        </Form>

    </>);
};

export default GameEdit;

