import React, {useEffect} from "react";

import News from "./news/News";
import Footer from "./Footer";
import {GoogleAnalyticsPageView} from "../GoogleAnalytics";
import {useCurrentUserContext} from "../user/CurrentUserContext";
import {useSearchParams} from "react-router-dom";


const MobileNotSupported = () => {

    GoogleAnalyticsPageView("/mobilenotsuported", "Mobile Not Supported")

    return (
        <>
           <p>im sorry, mobile version is not available for now</p>
        </>
    );
};

export default MobileNotSupported;

