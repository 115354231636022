import React, {useEffect, useState} from "react";
import {
    Button,
    Space,
    Table
} from "antd";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import {doRestCall} from "../../AppUtils";

const News = () => {

    GoogleAnalyticsPageView("/news", "News")

    const navigate = useNavigate();

    const [news, setNews] = useState([]);

    function load() {
        doRestCall('/news/smartroster', 'get', {}, null, (response) => setNews(response.body))
    }

    useEffect(() => {
        load();
    }, [])

    function view(id) {
        navigate('/news/edit/' + id)
    }

    function remove(id) {
        doRestCall( '/news/remove/' + id, 'delete')
    }

    const columns = [{
        title: 'Title', dataIndex: 'title', key: 'title',
        render: (name, row) => (
            <>
            <Button type="link" onClick={() => view(row.id)}>{name}</Button>
                <Button onClick={() => remove(row.id)}>Delete</Button>
            </>
        )
    }];


    return (
        <>
            <Auth />

              <>
                <h2>News</h2>
                  <Button data-cy="createNews" onClick={() => navigate("/news/create")}>Create News</Button>

                  <Table
                    columns={columns}
                    dataSource={news}
                    pagination={true}
                    id={"table"}
                    style={{width: '100%'}}
                    showHeader={true}
                />
              </>

        </>
    );
};

export default News;

