import axios from "axios";
import {getToken} from "../../user/auth/Auth";
import {restRoute} from "../../AppUtils";
import { saveAs } from 'file-saver'


export function createCrusadeRoster(roster) {
    return axios
        .post(restRoute() + '/crusade/roster/create', roster, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function updateCrusadeRoster(roster) {

    return axios
        .put(restRoute() + '/crusade/roster/update', roster, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}



export function loadCards(forceId) {
    return axios
        .get(restRoute() + '/crusade/roster/force/' + forceId + '/cards', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function forcesMy() {
    return axios
        .get(restRoute() + '/crusade/roster/forces/my', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function crusadeRostersMy() {
    return axios
        .get(restRoute() + '/crusade/rosters/my', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function crusadeRosterDelete(rosterId) {
    return axios
        .delete(restRoute() + '/crusade/roster/delete/' + rosterId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function crusadeRosterExport(rosterId) {
    return axios
        .get(restRoute() + '/crusade/roster/export/' + rosterId, {
            responseType: 'blob',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,

            },
        })
        .then((res) => {
            saveAs(res.data, 'file.txt');
        })
        .catch((error) => {
            console.log(error);
        });
}

export function crusadeRosterView(rosterId) {
    return axios
        .get(restRoute() + '/crusade/roster/view/' + rosterId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}


export function findLeaders(faction, name) {
    return axios
        .get(restRoute() + '/crusade/roster/findleaders/' + faction + "/" + name, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });

}

export function calculateSummary(cards, force) {
    return axios
        .post(restRoute() + '/crusade/roster/calculate', cards, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
            params: {
                force: force
            }
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
