import React from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {useNavigate} from "react-router-dom";
import {Button, Popconfirm} from "antd";
import {doRestCall} from "../../../AppUtils";

const EditButton = ({ tournamentId, ownerId, tournamentMasterId }) => {

    const { currentUser } = useCurrentUserContext();
    const navigate = useNavigate();

    const onEdit = () => {
        navigate("/tournament/edit/" + tournamentId)
    };

    return <>
        { (currentUser?.role === 'ADMIN' || currentUser?.id === ownerId || currentUser?.id === tournamentMasterId)
            && <Button onClick={onEdit}>Edit</Button>}
    </>
}

export default EditButton;