import React, {useEffect, useState} from 'react';
import {Button, InputNumber, Spin} from 'antd';

const Count = ({model, onUnitsChange}) => {

    return <>
        <InputNumber min={0} defaultValue={model.count} onChange={(e) => {
            model.count = e
            onUnitsChange()
        }}/>
    </>
}

export default Count;