import React, {useEffect, useState} from 'react';
import {Form, Select, Spin} from 'antd';
import {doRestCall} from "../../../AppUtils";
import {useNavigate} from "react-router-dom";

const Factions = ({setSelectedFaction}) => {
    const [factionOptions, setFactionOptions] = useState([])
    const [factions, setFactions] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (factions !== undefined && factions !== null) {
            let temp = []
            for (let i = 0; i < factions.length; i++) {
                temp[i] = {
                    value: factions[i],
                    label: factions[i],
                }
            }
            setFactionOptions(temp)
        }
    }, [factions]);

    useEffect(() => {
        doRestCall( '/codex/factions', 'get', null, null, (response) => setFactions(response.body))
    }, []);

    const onFactionChange = (value) => {
        setSelectedFaction(value)
    };

    return <>
        <Form.Item name="faction">
            <Select
                showSearch
                placeholder="Select faction"
                onChange={onFactionChange}
                options={factionOptions}
                style={{width: 200}}
            />
        </Form.Item>
    </>
}

export default Factions;