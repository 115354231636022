import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {
    forceView
} from "../ForceConnector";
import {Auth} from "../../../user/auth/Auth";

import {GoogleAnalyticsPageView} from "../../../GoogleAnalytics";
import {Space, Table} from "antd";
import CardPrint from "./CardPrint";

const ForcePrint = () => {

    GoogleAnalyticsPageView("/crusade/force/print", "Crusade Force Print")

    const navigate = useNavigate();

    let {forceId} = useParams();

    const [loading, setLoading] = useState(true)
    const [force, setForce] = useState({});
    const [cards, setCards] = useState([]);

    useEffect(() => {
        loadForce()
    }, [])

    const loadForce = () => {
        forceView(forceId)
            .then(response => {
                setForce(response.body)
                setCards(response.body.cards)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const columns = [{
        title: 'Name', dataIndex: 'name', key: 'name',
    },{
        title: 'Points', dataIndex: 'points', key: 'points',
    }, {
        title: 'CrusadePoints', dataIndex: 'crusadePoints', key: 'crusadePoints',
    },{
        title: 'Mark of Greatness', dataIndex: 'markOfGreatness', key: 'markOfGreatness',
    }, {
        title: 'Survived', dataIndex: 'survived', key: 'survived',
    }, {
        title: 'Enemy Units Destroyed', dataIndex: 'enemyUnitsDestroyed', key: 'enemyUnitsDestroyed',
    }

    ];

    return (
        <>
            <Auth/>

            <p className="forceManageTitle">{force.name}</p>
            <Space direction={"horizontal"}>
                <Space direction={"vertical"}>
                    Supply Limit
                    {force.supplyLimit}
                </Space>
                <Space direction={"vertical"}>
                    Supply Used
                    {force.supplyUsed}
                </Space>
                <Space direction={"vertical"}>
                    Battle Tally
                    {force.battleTally}
                </Space>
                <Space direction={"vertical"}>
                    Victories
                    {force.victories}
                </Space>
                <Space direction={"vertical"}>
                    Requisition Points
                    {force.reqPoints}
                </Space>
            </Space>

            <Table
                columns={columns}
                pagination={false}
                dataSource={force.cards}
                id={"table"}
                style={{width: '100%'}}
                showHeader={true}
                bordered={true}
                className="forceViewCardsTable"
            />

            <div style={{
                breakAfter: "page"
            }}/>

{/*
            {cards && cards.map(card => <>
                    <CardPrint card={card}/>
                    <div style={{
                        breakAfter: "page"
                    }}/>
                </>
            )}
*/}

        </>
    );
};

export default ForcePrint;

