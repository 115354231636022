import React from "react";
import "../styles.css";

import { Image, List, Typography} from 'antd';
import Link from "antd/lib/typography/Link";
import moment from "moment/moment";

const DefaultNewsItem = ({imageUrl, url, title, publishedDate}) => {

    return (
        <>
            {!imageUrl.endsWith('/null') && <Image className="newsListImg" src={imageUrl} preview={false} width={'100%'}/> }
            <Link href={url}>
                <Typography.Title className="newsTitle" level={3}>
                    {title}
                </Typography.Title>
            </Link>
            <Typography.Paragraph className="publishedDate">
                Published: {moment(publishedDate, 'YYYYMMDDhhmm').format('DD-MMM-YYYY')}
            </Typography.Paragraph>
        </>
    );
};

export default DefaultNewsItem;