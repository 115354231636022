import React, {useEffect, useState} from 'react';
import {Form, Select, Spin, Steps} from 'antd';

const Status = ({status}) => {

    const [current, setCurrent] = useState();

    useEffect(() => {
        switch (status) {
            case 'CREATED': setCurrent(0); break;
            case 'REGISTRATION_OPENED': setCurrent(1); break;
            case 'REGISTRATION_CLOSED': setCurrent(2); break;
            case 'STARTED': setCurrent(3); break;
            case 'ENDED': setCurrent(5); break;
        }
    }, [status]);

    return <>
        <Steps
            className="tournViewStatus"
            type="navigation"
            size="small"
            direction={"horizontal"}
            current={current}
            items={[
                {
                    title: 'Created',
                },
                {
                    title: 'Registration Open',
                },
                {
                    title: 'Registration Closed',
                },
               {
                    title: 'In Progress',
                },
               {
                    title: 'Ended',
                },
            ]}
        />
    </>
}

export default Status;