import React, {useEffect, useRef, useState} from 'react';
import {Form, Input, Select, Spin, Tag} from 'antd';
import {doRestCall} from "../../../AppUtils";
import {useNavigate} from "react-router-dom";
import { PlusOutlined } from '@ant-design/icons';
import { TweenOneGroup } from 'rc-tween-one';


const TournamentTags = ({tournamentId}) => {

    const [loading, setLoading] = useState(false);

    const [tags, setTags] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        doRestCall('/tournament/tags/' + tournamentId, 'get', null, null,
            (response) => {  setTags(response) })
    }, [])


    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);

    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        console.log(newTags);
        setTags(newTags);

        doRestCall('/tournament/tag/' + tournamentId + '/' + removedTag.id, 'delete')
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue]);
        }
        setInputVisible(false);
        setInputValue('');

        doRestCall('/tournament/tag', 'post', null, {
            tournamentId: tournamentId,
            label: inputValue
        })
    };

    const forMap = (tag) => (
        <span
            key={tag.key}
            style={{
                display: 'inline-block',
            }}
        >
      <Tag
          closable
          onClose={(e) => {
              e.preventDefault();
              handleClose(tag);
          }}
      >
        {tag.label}
      </Tag>
    </span>
    );
    const tagChild = tags.map(forMap);
    const tagPlusStyle = {
        borderStyle: 'dashed',
    };
    return <>
            <div
                style={{
                    width: '100%',
                    marginBottom: 16,
                }}
            >
                <TweenOneGroup
                    appear={false}
                    enter={{
                        scale: 0.8,
                        opacity: 0,
                        type: 'from',
                        duration: 100,
                    }}
                    leave={{
                        opacity: 0,
                        width: 0,
                        scale: 0,
                        duration: 200,
                    }}
                    onEnd={(e) => {
                        if (e.type === 'appear' || e.type === 'enter') {
                            e.target.style = 'display: inline-block';
                        }
                    }}
                >
                    {tagChild}
                </TweenOneGroup>
            </div>
            {inputVisible ? (
                <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={{
                        width: 78,
                    }}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                />
            ) : (
                <Tag onClick={showInput} style={tagPlusStyle}>
                    <PlusOutlined /> New Tag
                </Tag>
            )}
    </>
}

export default TournamentTags;