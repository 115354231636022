import React, {useEffect, useState} from "react";
import {Cascader, InputNumber, Space, Table, Tag, Checkbox, Select, Divider, Typography, Button, Dropdown} from 'antd';
import "../styles.css"
import CardInfo from "../../edit/components/CardInfo";

const {Text, Link} = Typography;

const Card = ({card, onLeaderSelected, data, setData}) => {

    const [hideCustomize, setHideCustomize] = useState(true);

    return (
        <div onMouseOver={event => setHideCustomize(false)}
             onMouseLeave={event => setHideCustomize(true)}>


            <Space direction="horizontal">
                {card.unit.warlord && <Text code>WARLORD</Text> }
                <Text strong className="rosterCreateParentName">{card.name}</Text>
                <CardInfo card={card} />
            </Space>
{/*
                    <Prices prices={unit.prices}/>
*/}
{/*
                    <Warlord unit={unit} hideCustomize={hideCustomize}/>
                    <Leader
                        unit={unit}
                        hideCustomize={hideCustomize}
                        onLeaderSelected={onLeaderSelected}/>
*/}

        </div>
    );

};

export default Card;













