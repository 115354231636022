import React, {useEffect, useState} from "react";
import {Button, Form, Input, List, Space, Table, Row, Col, Select} from "antd";
import {
    updateRoster,
    loadUnits,
    rosterView,
    codexFactions, rosterUpdate
} from "../RosterConnector";
import {useNavigate, useParams} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import './styles.css'
import {notificationFailure} from "../RosterNotifications";
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import Units from "./components/Units";

const RosterEdit = () => {

    GoogleAnalyticsPageView("/roster/edit", "Roster Edit")

    const [form] = Form.useForm();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [roster, setRoster] = useState({});


    const [factions, setFactions] = useState();
    const [selectedFaction, setSelectedFaction] = useState();
    const [factionOptions, setFactionOptions] = useState()

    const [summary, setSummary] = useState(0);

    let {rosterId} = useParams();

    useEffect(() => {
        form.setFieldsValue({
            id: roster.id,
            name: roster.name,
            createdAt: roster.createdAt
        })
    }, [roster]);

    useEffect(() => {
        codexFactions()
            .then(response => {
                    setFactions(response.body)
                }
            )
            .catch(error => {
                console.log(error)
            })
    }, []);

    useEffect(() => {
        if (factions !== undefined && factions !== null) {
            let temp = []
            for (let i = 0; i < factions.length; i++) {
                temp[i] = {
                    value: factions[i],
                    label: factions[i],
                }
            }
            setFactionOptions(temp)
        }
    }, [factions]);

    useEffect(() => {
        if (factionOptions !== undefined && factionOptions !== null) {
            form.setFieldsValue({
                faction: roster.faction
            })
            setSelectedFaction(roster.faction)
        }
    }, [roster]);

    useEffect(() => {
        if (factionOptions !== undefined && factionOptions !== null) {
            rosterUpdate(rosterId)
                .then(response => {
                    setRoster(response.body)
                    setLoading(false)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [factionOptions])


    const onFactionChange = (value) => {
        setSelectedFaction(value)
    };

    const save = (roster) => {
        updateRoster(roster)
            .then(response => {
                    if (response.valid) {
                        navigate('/roster/view/' + rosterId)
                    }
                    if (!response.valid) {
                        response.errors.forEach(error => notificationFailure(error.error))
                    }
                }
            )
            .catch(error => {
                console.log(error)
            })
    }

    function onCancel() {
        navigate('/roster/view/' + rosterId)
    }

    function onFinish(values) {
        save(values)
    }

    return (
        <>
            <Auth/>
            {loading && <p>loading</p>}
            {!loading &&
                <>
                    {roster && <p className="rosterEditTitle">Roster Edit {roster.name}</p>}
                    <Form id={"form"}
                          onFinish={onFinish}
                          form={form}
                          style={{width: '100%'}}>

                        <Form.Item name="id" hidden="true">
                            <Input/>
                        </Form.Item>

                        <Form.Item name="createdAt" hidden="true">
                            <Input/>
                        </Form.Item>

                        <Row justify="space-around">
                            <Col span={10}>
                                <Space style={{width: '100%', justifyContent: 'left'}}>
                                    <Form.Item name={"name"} style={{width: '100%'}} label="Name">
                                        <Input style={{width: '200%'}}/>
                                    </Form.Item>
                                </Space>

                            </Col>

                            <Col span={10}>
                                <Form.Item name={"summary"} style={{width: '100%'}}>
                                    <Space style={{width: '100%', justifyContent: 'right'}}>
                                        <p>Summary</p>
                                        {summary}
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item name="faction">
                            <Select
                                showSearch
                                placeholder="Select faction"
                                onChange={onFactionChange}
                                options={factionOptions}
                                style={{width: 400}}
                            />
                        </Form.Item>

                        <Units units={roster.units}
                               form={form}
                               setSummary={setSummary}
                               selectedFaction={selectedFaction} />

                        <Row justify="space-around" className="rosterEditButtons">
                            <Col span={10}></Col>

                            <Col span={10}>
                                <Space direction={"horizontal"} style={{width: '100%', justifyContent: 'right'}}>
                                    <Button onClick={() => onCancel()}>Cancel</Button>
                                    <Button type={"primary"} htmlType={"submit"}>Update</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </>
            }
        </>
    );
};

export default RosterEdit;

