import React, { useState} from "react";
import {InfoCircleOutlined} from "@ant-design/icons";
import {Modal} from "antd";
import {Typography} from 'antd';
const {Text, Link} = Typography;


const CardInfo = ({card}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    return <>
        <InfoCircleOutlined onClick={showModal}/>
        <Modal title={card.name} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <p>Unit Name: {card.unit.name}</p>
            <p>Weapons: <Text>{
                card.unit?.models
                    .flatMap(model => model.weapons)
                    .map(weapon => weapon.name)
                    .join(", ")}</Text></p>
            <p>{card.enhancements ? card.enhancements : 'no enhancements'}</p>
            <p>{card.battleHonors ? card.battleHonors : 'no battle honors'}</p>
            <p>{card.battleScars ? card.battleScars : 'no battle scars'}</p>
        </Modal>
    </>
}

export default CardInfo;