import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Form,
    Input,
    Select, Space,
    Table
} from "antd";

import {useNavigate} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import Errors from "./components/Errors";
import Universes from "./components/Universes";
import Participants from "./components/Participants";
import {doRestCall} from "../../AppUtils";

const {TextArea} = Input;


const GameCreate = () => {

    GoogleAnalyticsPageView("/game/create", "Game Create")

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const [usersLoading, setUsersLoading] = useState(true)
    const [users, setUsers] = useState({});
    const [errors, setErrors] = useState(undefined)
    const [selectedSettings, setSelectedSettings] = useState([])
    const [selectedUniverse, setSelectedUniverse] = useState()


    useEffect(() => {
        doRestCall( '/users/all', 'get', null, null, onSuccessUsersAll)
    }, [])

    useEffect(() => {
        form.setFieldsValue({universe: selectedUniverse});
    }, [selectedUniverse])

    function navigateBack() {
        navigate("/games")
    }

    function onSuccessUsersAll(response) {
        for (let i = 0; i < response.body.length; i++) {
            response.body[i] = {
                id: response.body[i].id,
                name: response.body[i].username,
                value: response.body[i].id,
                label: response.body[i].username,
            }
        }

        setUsers(response.body)
        setUsersLoading(false)
    }

    function onSuccessCreate(response) {
        if (response.valid) {
            setErrors([])
            navigate('/game/view/' + response.body.id)
        }

        if (!response.valid) {
            setErrors(response.errors)
        }
    }

    const save = (game) => {
        doRestCall( '/game/create', 'post', null, game, onSuccessCreate)
    }

    function onFinish(values) {
        save(values)
    }

    const filterGameMasters = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <>
            <Auth/>
            {usersLoading && <p>loading</p>}
            {!usersLoading &&
                <>
                    <h2 className="gameCreateTitle">Create New Game</h2>

                    <Errors errors={errors}/>

                    <Form id={"form"}
                          onFinish={onFinish}
                          form={form}
                          className="gameCreateForm"
                          layout="horizontal"
                    >

                        <Form.Item
                            className="gameCreateLabel"
                            label="Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input name!',
                                },
                            ]}
                        >
                            <Input className="gameCreateInput" data-cy="name"/>
                        </Form.Item>

                        <Form.Item
                            className="gameCreateLabel"
                            label="Date"
                            name="dateTime"
                        >
                            <DatePicker className="gameCreateDate" data-cy="dateTime"/>
                        </Form.Item>

                        <Form.Item
                            className="gameCreateLabel"
                            label="Universe"
                            name="universe"
                            style={{width: '8rem', justifyContent: 'start', display: 'flex'}}
                        >
                            <Universes setSelectedUniverse={setSelectedUniverse}/>
                        </Form.Item>

                        <Form.Item
                            className="gameCreateLabel"
                            label="Game Master"
                            name="gameMasterId"
                        >
                            <Select
                                className="gameCreateSelect"
                                options={users}
                                data-cy="gameMaster"
                                showSearch
                                filterOption={filterGameMasters}
                            />
                        </Form.Item>

                        <Form.Item
                            className="gameCreateLabel"
                            label="Notes"
                            name="notes"
                        >
                            <TextArea rows={6} maxLength={1000}/>
                        </Form.Item>

                        <Participants form={form} selectedUniverse={selectedUniverse} />

                        <Space className="gameCreateButtons" direction="horizontal">
                            <Button onClick={navigateBack}>Back</Button>
                            <Button type="primary" htmlType="submit" data-cy="submit">
                                Submit
                            </Button>
                        </Space>

                    </Form>
                </>
            }
        </>
    );
};

export default GameCreate;

