import {useNavigate, useNavigation} from "react-router-dom";
import axios from "axios";
import {getToken} from "./user/auth/Auth";


export function isHome() {
    return window.location.pathname === "/";
}

export function restRoute() {

    const {hostname} = window.location

    switch (hostname) {
        case '127.0.0.1':
            return 'http://127.0.0.1:8071/rest'
        case 'localhost':
            return 'http://127.0.0.1:8071/rest'
    }

    return 'http://smartroster.club/rest'
}


export function env() {

    const {hostname} = window.location

    switch (hostname) {
        case 'smartroster.club':
            return 'PROD';
    }

    return 'DEV';
}

export function doRestCall(url, method, params = {}, data = {}, onSuccess = null, onError = null, onFinally = null) {
    axios.request({
        baseURL: restRoute(),
        url: url,
        method: method,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + getToken().value,
        },
        params: params,
        data: data,
    })
        .then((response) => {
            onSuccess && onSuccess(response.data);
        })
        .catch((error) => {
            console.log(error);
            onError && onError(error)
        })
        .finally(() => {
            onFinally && onFinally()
        });
}

/*
export function useRestCall() {
    const doRestCall = async (navigate, url, method, params, data) => {
        return axios.request({
            baseURL: restRoute(),
            url: url,
            method: method,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
            params: params,
            data: data,
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (isAccessError(error)) {
                    navigate('/user/login')
                } else {
                    console.log(error);
                }
            });
    }

    return {doRestCall}
}
*/

export function isAccessError(error) {

    if (error === undefined || error === null) {
        console.log("Access Error")
        return true;
    }
    if (error.response === undefined || error.response === null) {
        console.log("Access Error")
        return true;
    }
    if (error.response.status === undefined || error.response.status === null
        || error.response.status === 403
        || error.response.status === 401) {
        console.log("Access Error")
        return true;
    }
    return false;
}