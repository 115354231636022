import React, {useEffect, useState} from 'react';
import {Form, Select, Spin} from 'antd';
import {doRestCall} from "../../../AppUtils";
import {useNavigate} from "react-router-dom";

const Winner = ({players, winner, gameId}) => {

    const [items, setItems] = useState([])
    const [itemWinner, setItemWinner] = useState(winner)

    useEffect(() => {
        const temp = [{
            value: 'None',
            label: 'None'
        }, {
            value: 'Draw',
            label: 'Draw'
        }];

        for (let i = 0; i < players?.length; i++) {
            temp[i + 2] = {
                label: players[i].id,
                value: players[i].username,
            }
        }

        setItems(temp)
    }, [players]);

    useEffect(() => {
        setItemWinner(winner)
    }, [winner])


    function updateWinner(gameId, winner) {
        doRestCall('/game/update/winner', 'put', null, {
            gameId: gameId,
            winner: winner
        })
    }

    return <>
        <Select
            defaultValue={itemWinner}
            options={items}
            onChange={(winner) => updateWinner(gameId, winner)}
        />
    </>
}

export default Winner;