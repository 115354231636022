import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Dropdown, List, Space, Spin, Typography
} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import {Auth} from "../../user/auth/Auth";
import {codexFactions, loadUnitsByFaction} from "../CodexConnector";
import {DownOutlined} from '@ant-design/icons';
import Title from "antd/lib/typography/Title";
import {doRestCall} from "../../AppUtils";


const CodexUnits = () => {

    GoogleAnalyticsPageView("/codex/units", "Codex Units")

    const {Text, Link} = Typography;

    const navigate = useNavigate();

    const [reimportLoading, setReimportLoading] = useState(false)
    const [universesLoading, setUniversesLoading] = useState(false)
    const [factionsLoading, setFactionsLoading] = useState(false)

    const [factions, setFactions] = useState([])
    const [universes, setUniverses] = useState([])
    const [units, setUnits] = useState([])
    const [selectedFaction, setSelectedFaction] = useState("Select faction")
    const [selectedUniverse, setSelectedUniverse] = useState("Select universe")

    function loadUniverses() {
        setUniverses('Select universe')
        setUniversesLoading(true)
        doRestCall('/codex/universes', 'get', null, null,
            (response) => {
                let tempItems = []
                for (let i = 0; i < response.body.length; i++) {
                    tempItems[i] = {
                        key: response.body[i],
                        label: response.body[i]
                    }
                }
                setUniverses(tempItems)
            }, null,
            () => {
                setUniversesLoading(false)
            })
    }

    useEffect(() => {
        loadUniverses();
    }, []);

    useEffect(() => {
        setFactionsLoading(true)
        doRestCall('/codex/factions', 'get', {
                universe: selectedUniverse,
            }, null,
            (response) => {
                let tempItems = []
                for (let i = 0; i < response.body.length; i++) {
                    tempItems[i] = {
                        key: response.body[i],
                        label: response.body[i]
                    }
                }

                setFactions(tempItems)
            }, null,
            () => {
                setFactionsLoading(false)
            })
    }, [selectedUniverse]);

    useEffect(() => {
        doRestCall('/codex/units/' + selectedFaction, 'get', null, null,
            (response) => {
                setUnits(response.body)
            })
    }, [selectedFaction])

    const onSelectFaction = ({key}) => {
        setSelectedFaction(key)
    };

    const onSelectUniverse = ({key}) => {
        setSelectedUniverse(key)
    };

    const reimport = () => {
        setReimportLoading(true)
        doRestCall('/codex/reimport', 'post', null, null,
            () => {
                setFactions([])
                setUnits([])
                setSelectedFaction('Select faction')
                setSelectedUniverse('Select universe')

                loadUniverses()
            }, null,
            () => {
                setReimportLoading(false)
            })

    }

    return (
        <>
            <Auth/>

            <p className="">Codex Units</p>

            <Spin size={"large"} spinning={reimportLoading}>
                <Button onClick={() => reimport()}>Reimport</Button>


                <Spin size={"large"} spinning={universesLoading}>
                    {universes && <Dropdown
                        menu={{
                            items: universes,
                            onClick: onSelectUniverse
                        }}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                {selectedUniverse} <DownOutlined/>
                            </Space>
                        </a>
                    </Dropdown>}

                    <Spin size={"large"} spinning={factionsLoading}>

                        {factions && <Dropdown
                            menu={{
                                items: factions,
                                onClick: onSelectFaction
                            }}
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    {selectedFaction} <DownOutlined/>
                                </Space>
                            </a>
                        </Dropdown>}

                    </Spin>
                </Spin>

                {units && <List
                    grid={{
                        gutter: 16,
                        column: 4,
                    }}
                    dataSource={units}
                    renderItem={(item) => (
                        <List.Item>
                            <Card
                                hoverable={true}
                                size="small"
                                title={item.name}
                                style={{
                                    width: 300,
                                }}
                            >
                                <Space direction="horizontal">
                                    {item.m} {item.t} {item.sv} {item.w} {item.ld} {item.oc}
                                </Space>
                            </Card>
                            {/*                      <Space direction="vertical" align="start">
                            <Space direction="horizontal">
                                {item.name} <Text strong>{JSON.stringify(item.prices)}</Text>
                            </Space>
                            <Space direction="horizontal">
                                {item.m} {item.t} {item.sv} {item.w} {item.ld} {item.oc}
                            </Space>
                            <Space direction="vertical" align="start">
                                {item.models.map(model =>
                                    <Space direction="vertical" align="start">
                                        {model.name}
                                        <Space direction="horizontal">&nbsp;
                                            {model.weapons
                                                .map(weapon => weapon.defaultWeapon ? <Text strong>{weapon.name}</Text> : <Text>{weapon.name}</Text>)
                                                }
                                        </Space>
                                    </Space>)}
                            </Space>
                        </Space>
   */}                 </List.Item>
                    )}
                />}
            </Spin>
        </>
    );
};

export default CodexUnits;

