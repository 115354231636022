import React, {useEffect, useState} from "react";
import "../styles.css";

import {newsWC} from "./NewsConnector";
import {Image, List, Typography} from 'antd';
import SmartRosterNewsItem from "./SmartRosterNewsItem";
import DefaultNewsItem from "./DefaultNewsItem";

const News = () => {
    const [loading, setLoading] = useState(true)
    const [news, setNews] = useState({});

    useEffect(() => {
        load()
    }, [])

    const load = () => {
        newsWC()
            .then(response => {
                setNews(response.body)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <>
            {loading && <p>loading</p>}
            {!loading &&

                <List
                    className="newsList"
                    itemLayout="vertical"
                    grid={{column: 3}}
                    bordered
                    pagination={true}
                    dataSource={news}
                    renderItem={(item) => (
                        <List.Item id="newsListItem">

                            {item.source === 'SMARTROSTER' ?
                                <SmartRosterNewsItem
                                    imageUrl={item.imageUrl}
                                    url={item.url}
                                    title={item.title}
                                    body={item.body}
                                    publishedDate={item.publishedDate}
                                /> : <DefaultNewsItem
                                    imageUrl={item.imageUrl}
                                    url={item.url}
                                    title={item.title}
                                    publishedDate={item.publishedDate}
                                />}

                        </List.Item>
                    )}
                />

            }
        </>
    );
};

export default News;