import React, {useEffect, useState} from "react";
import {Button, Form, Input, Space, Row, Col, Select} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import './styles.css'
import {GoogleAnalyticsPageView} from "../../../GoogleAnalytics";
import {Auth} from "../../../user/auth/Auth";
import {rosterView, updateRoster} from "../../../roster/RosterConnector";
import Forces from "../edit/components/Forces";
import Cards from "../edit/components/Cards";
import {crusadeRosterView, updateCrusadeRoster} from "../CrusadeRosterConnector";
import {notificationFailure} from "../../../roster/RosterNotifications";


const CrusadeRosterEdit = () => {

    GoogleAnalyticsPageView("/crusade/roster/edit", "Crusade Roster Edit")

    const [form] = Form.useForm();
    const navigate = useNavigate();

    let {crusadeRosterId} = useParams();

    const [roster, setRoster] = useState({});
    const [summary, setSummary] = useState(0);
    const [selectedForce, setSelectedForce] = useState(undefined);

    useEffect(() => {
        if (roster !== undefined && roster !== null) {
            form.setFieldsValue({
                name: roster.name,
                id: roster.id,
                createdAt: roster.createdAt,
            })
            if (roster.force !== undefined && roster.force != null) {
                onForceSelected(roster.force.id)
            }
        }
    }, [roster]);

    useEffect(() => {
        if (crusadeRosterId !== undefined && crusadeRosterId !== null) {
            crusadeRosterView(crusadeRosterId)
                .then(response => {
                    setRoster(response.body)
                    console.log("useEffect crusadeRosterId roster loaded", crusadeRosterId)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [crusadeRosterId])

    const onForcesLoaded = () => {
/*
        setSelectedForce(roster.force.id)
        console.log("onForcesLoaded", roster, roster.force.id)
        form.setFieldsValue({forceId: roster.force.id});
*/
    }

    const onForceSelected = (value) => {
        console.log("onForceSelected", value)
        setSelectedForce(value)
        form.setFieldsValue({forceId: value});
    }

    function onCancel() {
        navigate('/crusade/roster/view/' + crusadeRosterId)
    }

    function onFinish(values) {
            save(values)
    }

    const save = (roster) => {
        console.log("save", roster)
        updateCrusadeRoster(roster)
            .then(response => {
                    if (response.valid) {
                        navigate('/crusade/roster/view/' + crusadeRosterId)
                    }
                    if (!response.valid) {
                        response.errors.forEach(error => notificationFailure(error.error))
                    }
                }
            )
            .catch(error => {
                console.log(error)
            })
    }


    return (
        <>
            <Auth/>

            <p className="rosterCreateTitle">Edit Crusade Roster {roster && roster.name}</p>

            <Form id={"form"}
                  onFinish={onFinish}
                  form={form}
                  style={{width: '100%'}}>

                <Row justify="space-around">
                    <Col span={10}>
                        <Form.Item id="name" name="name" label="Name">
                            <Input/>
                        </Form.Item>

                        <Forces onForceSelected={onForceSelected} onForcesLoaded={onForcesLoaded}/>
                    </Col>

                    <Col span={10}>
                        <Form.Item name={"summary"} style={{width: '100%'}}>
                            <Space style={{width: '100%', justifyContent: 'right'}}>
                                <p>Summary:</p>
                                {summary}
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>

                <Cards form={form}
                       setSummary={setSummary}
                       selectedForce={selectedForce}
                       roster={roster}
                       cards={roster.cards}/>

                <Form.Item name="id" hidden="true">
                    <Input/>
                </Form.Item>

                <Form.Item name="createdAt" hidden="true">
                    <Input/>
                </Form.Item>

                <Row justify="space-around" className="rosterCreateButtons">
                    <Col span={10}></Col>

                    <Col span={10}>
                        <Space direction={"horizontal"} style={{width: '100%', justifyContent: 'right'}}>
                            <Button onClick={onCancel}>Cancel</Button>
                            <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        </Space>
                    </Col>
                </Row>

            </Form>


        </>
    );
};

export default CrusadeRosterEdit;

