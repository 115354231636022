import React, {useEffect, useState} from "react";
import {Form, Table} from "antd";
import Factions from "./Factions";
import {doRestCall} from "../../../AppUtils";



const Participants = ({tournamentId, tournamentPlayers, selectedUniverse}) => {

    const [userTableSelectedRowKeys, setUserTableSelectedRowKeys] = useState();
    const [users, setUsers] = useState([]);
    const [players, setPlayers] = useState([]);

    useEffect(() => {
        doRestCall( '/tournament/users', 'get', null, null, onAllUsersSuccess)
    }, [])


    useEffect(() => {
        for (let i = 0; i < tournamentPlayers?.length; i++) {
            tournamentPlayers[i] = {
                id: tournamentPlayers[i].id,
                name: tournamentPlayers[i].username,
                value: tournamentPlayers[i].id,
                label: tournamentPlayers[i].username,
                key: tournamentPlayers[i].key,
                score: tournamentPlayers[i].score,
                faction: tournamentPlayers[i].faction
            }
        }

        setPlayers(tournamentPlayers)

    }, [tournamentPlayers])

    useEffect(() => {
        players && setUserTableSelectedRowKeys(users.filter(value => players.map(tp => tp.id).includes(value.id)).map(value => value.key));
    }, [users, players])


    function onAllUsersSuccess(response) {
        for (let i = 0; i < response.body.length; i++) {
            response.body[i] = {
                id: response.body[i].id,
                name: response.body[i].username,
                value: response.body[i].id,
                label: response.body[i].username,
                key: response.body[i].key,
                faction: response.body[i].faction
            }
        }
        setUsers(response.body)
    }

    const usersTableColumns = [{
        title: 'Name', dataIndex: 'label', key: 'key', width: '60%',
    }, {
        title: 'Faction', dataIndex: 'faction', key: 'faction', width: '40%',
        render: (_, row) => (<>
                <Factions selectedUniverse={selectedUniverse} preSelectedFaction={row.faction} setSelectedFaction={(value) => {
                    doRestCall('/tournament/update/participant/faction', 'put', null, {
                        tournamentId: tournamentId,
                        participantId: row.id,
                        faction: value
                    })
                }}
                />
            </>
        )
    }
    ];

    const usersRowSelection = {
        selectedRowKeys: userTableSelectedRowKeys, onChange: (selectedRowKeys, selectedRows) => {
            doRestCall('/tournament/update/participants', 'put', null, {
                tournamentId: tournamentId,
                participantIds: selectedRows.map(row => row.id)
            })
            setUserTableSelectedRowKeys(selectedRowKeys);
        },
    };

    return (
        <>

            {(users.length === 0) &&
                <>
                    <h2 className="tournEditTableTitles">Participants:</h2>
                    <div className="tournEditNoTable"> No Participants Found</div>
                </>
            }
            {(users.length > 0) &&
                <>
                    <h2 className="tournEditTableTitles">Participants:</h2>
                    <Form.Item name="usersFormItem">
                        <Table
                            columns={usersTableColumns}
                            dataSource={users}
                            pagination={
                                {
                                    pageSize: 10
                                }
                            }
                            id={"usersTable"}
                            style={{width: '100%'}}
                            bordered
                            showHeader={true}
                            rowSelection={{
                                type: 'checkbox', ...usersRowSelection,
                            }}
                            className="tournEditTable"
                        />
                    </Form.Item>
                </>
            }

        </>
    );
};

export default Participants;

