import React, {useEffect, useState} from 'react';
import {Badge, Form, Select, Space, Spin, Steps, Tag} from 'antd';
import {LikeFilled, LikeOutlined} from "@ant-design/icons";

const TournamentTag = ({key, id, label, voted, count, vote, unvote}) => {
    return <>
        <Space direction={"horizontal"}>
            <Badge count={count} >
                <Tag key={key}>{label}</Tag>
            </Badge>
            {voted && <LikeFilled onClick={() => unvote(id)} />}
            {!voted && <LikeOutlined onClick={() => vote(id)} />}
        </Space>
    </>
}

export default TournamentTag;