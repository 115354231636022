import React, {useEffect, useMemo, useState} from "react";
import {Alert, Button, Form, Input} from "antd";
import {loginUser, registerUser} from "../UserConnector";
import {useNavigate} from "react-router-dom";
import {Auth, removeToken, saveToken} from "../auth/Auth";
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import {useCurrentUserContext} from "../CurrentUserContext";


const UserLogout = () => {

    GoogleAnalyticsPageView("/user/logout", "User Logout")

    const navigate = useNavigate();
    const { setCurrentUser } = useCurrentUserContext();

    useEffect(() => {
        setCurrentUser(undefined)
        removeToken()
        navigate('/user/login')
    }, [])

    return (
        <>
            <Auth />
        </>
    );
};

export default UserLogout;

