import {Button, Dropdown, Input, Space, Form, Tag} from "antd";
import React, {useEffect, useState} from "react";
import {findLeaders} from "../../RosterConnector";
import {CloseCircleOutlined, CloseOutlined} from '@ant-design/icons';

const Leader = ({unit, hideCustomize, onUnitsChange}) => {
    const [items, setItems] = useState([])
    const [leaders, setLeaders] = useState([])

    const onClick = ({key}) => {
        if (unit.leaders === null || unit.leaders === undefined) {
            unit.leaders = [key]
        } else {
            unit.leaders =  [...leaders, key]
            setLeaders(leaders => [...leaders, key]);
        }

        onUnitsChange();
    };

    const clearLeader = (leader) => {
        let filter = unit.leaders.filter(item => item !== leader);
        setLeaders(filter)
        unit.leaders = filter

        onUnitsChange()
    };

    useEffect(() => {
        if (unit !== undefined) {
            findLeaders(unit.faction, unit.name)
                .then(response => {
                    unit.leaderOptions = response.body;
                })
        }
    }, [unit])

    useEffect(() => {
        let tempItems = []
        if (unit.leaderOptions !== undefined && unit.leaderOptions !== null) {
            for (let i = 0; i < unit.leaderOptions.length; i++) {
                tempItems[i] = {
                    key: unit.leaderOptions[i].name,
                    label: unit.leaderOptions[i].name
                }
            }
        }
        setItems(tempItems)

    }, [unit.leaderOptions])

    useEffect(() => {
        if (unit !== undefined && unit !== null) {
            setLeaders(unit.leaders)
        }

    }, [unit])

    return (<>
            <Space wrap>
                {leaders && leaders.map(leader => {
                    return <Tag
                        key={leader}
                        closable={true}
                        closeIcon={<CloseCircleOutlined/>}
                        onClose={() => clearLeader(leader)}>{leader}</Tag>
                })}
            </Space>

            {!hideCustomize &&<Dropdown
                menu={{
                    items,
                    onClick,
                }}
            >
                { <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        Assign a Leader
                    </Space>
                </a> }
            </Dropdown> }
        </>
    )
}


export default Leader;
