import React, { useState} from "react";
import {Avatar, Menu, Space} from "antd";
import { useNavigate } from "react-router-dom";
import {isUserAuthenticated} from "../user/auth/Auth";
import {useCurrentUserContext} from "../user/CurrentUserContext";
import {UserOutlined} from "@ant-design/icons";

const AuthenticatedUserMainMenu = () => {
    const navigate = useNavigate();

    const [current, setCurrent] = useState('home');
    const { currentUser } = useCurrentUserContext();

    const onClick = (e) => {
        setCurrent(e.key);
        navigate(e.key)
    };

    function buildItem(label, key, icon, children, type, style) {
        return {
            key,
            icon,
            children,
            label,
            type,
            style,
        };
    }

    const menuItems = [
        buildItem('Home', '/home', null, null, null, {float: 'left'}),
/*
        buildItem('Crusade', '/crusade', null, [
            buildItem('My Forces', '/crusade/forces/my'),
            buildItem('Create New Force', '/crusade/force/create', ),
            buildItem('My Rosters', '/crusade/rosters/my'),
            buildItem('Create New Roster', '/crusade/roster/create', ),
        ], null, {float: 'left'}),
*/
/*
        buildItem('Rosters', '/rosters', null, [
            buildItem('My Rosters', '/rosters/my'),
            buildItem('Create New Roster', '/roster/create', ),
        ], null, {float: 'left'}),
*/
        buildItem('Games', '/games', null , null, null, {float: 'left'}),
        buildItem('Tournaments', '/tournaments', null, null, null, {float: 'left'}),
        buildItem('Crusade', '/crusade/forces', null, null, null, {float: 'left'}),
/*
        buildItem('Codex', '/codex/units', null, null, null, {float: 'left'}),
*/
        buildItem(null, '/user', <Space> {currentUser && currentUser.username} <Avatar data-cy="userInfo" shape="square" size="small" icon={<UserOutlined />} /></Space>, [
            buildItem('Account', '/user/account'),
            buildItem('Logout', '/user/logout'),
        ], null, {float: 'right'}),
    ];

    return (
        <>
            {
            isUserAuthenticated(currentUser) && (
                <Menu
                    key="mainMenu"
                    onClick={onClick}
                    selectedKeys={[current]}
                    mode="horizontal"
                    items={menuItems}
                    style={{display: 'block'}}
                />
            ) }
        </>
    );
};

export default AuthenticatedUserMainMenu;

