import React from "react";
import "../styles.css";

import {Image, List, Space, Typography} from 'antd';
import Link from "antd/lib/typography/Link";
import moment from "moment/moment";
import MarkdownPreview from "@uiw/react-markdown-preview";

const SmartRosterNewsItem = ({imageUrl, title, body, publishedDate}) => {

    return (
        <>
            <Space direction={"vertical"}>
            <Image className="newsListImg" src={"/logo.png" } preview={false} width={'50%'}
                   style={{justifyContent: 'center'}}
            />
            <Typography.Title className="newsTitle" level={3}>
                {title}
            </Typography.Title>

            <MarkdownPreview
                source={body}
            />

            <br />

            <Typography.Paragraph className="publishedDate">
                Published: {moment(publishedDate, 'YYYYMMDDhhmm').format('DD-MMM-YYYY')}
            </Typography.Paragraph>
            </Space>
        </>
    );
};

export default SmartRosterNewsItem;