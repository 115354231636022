import React, {useEffect, useState} from 'react';
import {Alert, Button, Divider, Form, Select, Space, Spin, Steps} from 'antd';
import moment from "moment";
import Factions from "./Factions";
import {doRestCall} from "../../../AppUtils";
import {useNavigate} from "react-router-dom";

const Registration = ({ status, tournamentId, onTournamentUpdate, participantsCount, participantsLimit }) => {

    const [registrationStatus, setRegistrationStatus] = useState(true);
    const [selectedFaction, setSelectedFaction] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (tournamentId !== undefined && tournamentId !== null) {
            doRestCall( '/tournament/isregistered/' + tournamentId, 'get', null, null,
                (response) => setRegistrationStatus(response.body))
        }
    }, [tournamentId, registrationStatus]);

    const registerMe = () => {
        setRegistrationStatus(undefined)

       doRestCall( '/tournament/register/' + tournamentId, 'post',
            null, {faction: selectedFaction}, () => onTournamentUpdate() )
    };

    const unRegisterMe = () => {
        setRegistrationStatus(undefined)

        doRestCall( '/tournament/unregister/' + tournamentId, 'post', null,null,
            () => onTournamentUpdate())
    };

    return <>
        {status && status === 'CREATED' && <Space>
            <Alert className="tournRegistrationAlert" message="Registration not started yet" type={"warning"} showIcon />
        </Space>}
        {status && status === 'REGISTRATION_OPENED' && <>

            {registrationStatus === 'NOT_REGISTERED' && participantsCount < participantsLimit && <Space direction={"vertical"}>
                <Alert className="tournRegistrationAlert" message="Registration is open" type={"success"} showIcon />
                <Factions setSelectedFaction={setSelectedFaction}  />
                <Button onClick={registerMe}>register for tournament</Button>
            </Space>}

            {registrationStatus === 'NOT_REGISTERED' && participantsCount >= participantsLimit && <Space direction={"vertical"}>
                <Alert message="Tournament is full" type={"warning"} showIcon />
            </Space>}

            {registrationStatus === 'REGISTERED' && <Space direction={"vertical"}>
                <Alert message="You are already registered" type={"info"} showIcon />
                <Button onClick={unRegisterMe}>unregister for tournament</Button>
            </Space>}
        </>}
        {status && status === 'REGISTRATION_CLOSED' && <Space >
            <Alert message="Registration is closed" type={"info"} showIcon />
        </Space> }
    </>
}

export default Registration;