import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Form, InputNumber, Select, Space, Table} from "antd";
import {usersAll} from "../../../user/UserConnector";
import moment from "moment/moment";
import Factions from "./Factions";
import {DeleteOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {doRestCall} from "../../../AppUtils";


const Participants = ({form, selectedUniverse}) => {
    const navigate = useNavigate();

    const [players, setPlayers] = useState([]);
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [selectedPlayerId, setSelectedPlayerId] = useState([]);

    const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

    useEffect(() => {
        selectedPlayers?.length >= 2 ? setIsAddButtonDisabled(true) : setIsAddButtonDisabled(false)
    }, [selectedPlayers])

    function onSuccess(response) {
        for (let i = 0; i < response.body.length; i++) {
            response.body[i] = {
                id: response.body[i].id,
                key: response.body[i].key,
                name: response.body[i].username,
                value: response.body[i].id,
                label: response.body[i].username,
            }
        }

        setPlayers(response.body)
    }

    useEffect(() => {
        doRestCall( '/users/all', 'get', {}, {}, onSuccess)
    }, []);

    useEffect(() => {
        let obj = []
        selectedPlayers && selectedPlayers.map(player => {
            obj.push({
                "userId": player.id,
                "winner": player.winner,
                "score": player.score,
                "faction": player.faction
            })
        });

        form.setFieldsValue({
            players: obj
        })
    }, [selectedPlayers])

    const handleDelete = (key) => {
        const newData = selectedPlayers.filter((item) => item.key !== key);
        setSelectedPlayers(newData);
    };

    const handleAdd = () => {
        const newData = players.filter(player => player.id === selectedPlayerId)[0]
        setSelectedPlayers([...selectedPlayers, newData]);
    };

    const onPlayerSelected = (selectedPlayerId) => {
        setSelectedPlayerId(selectedPlayerId);
        setIsAddButtonDisabled(selectedPlayerId === undefined  || selectedPlayers?.length >= 2);
    };

    const onWinnerChange = (row, winner, isChecked) => {
        let p = selectedPlayers.find(player => player.id === row.id);
        p.winner = isChecked;

        setSelectedPlayers([...selectedPlayers])
    }

    const onFactionChange = (row, faction) => {
        let p = selectedPlayers.find(player => player.id === row.id);
        p.faction = faction;

        setSelectedPlayers([...selectedPlayers])
    }

    const onScoreChanged = (row, score) => {
        let p = selectedPlayers.find(player => player.id === row.id);
        p.score = score;

        setSelectedPlayers([...selectedPlayers])
    }

    const columns = [{
        title: 'Name', dataIndex: 'name', key: 'name',
    },{
        title: 'Faction', dataIndex: 'faction', key: 'faction',
        render: (faction, row) => (
            <Factions selectedUniverse={selectedUniverse} setSelectedFaction={(faction) => onFactionChange(row, faction)} />
        )
    },{
        title: 'Score', dataIndex: 'score', key: 'score',
        render: (score, row) => (
            <InputNumber min={0} onChange={(value) => onScoreChanged(row, value)} />
        )
    },{
        title: 'Winner', dataIndex: 'winner', key: 'winner',
        render: (winner, row) => (
            <Checkbox onChange={(e) => onWinnerChange(row, winner, e.target.checked)} />
        )
    },{
        title: 'Actions', dataIndex: 'actions', key: 'actions',
        render: (_, record) => (
            <Space direction="horizontal"><Button icon={<DeleteOutlined />} onClick={() => handleDelete(record.key)}></Button></Space>
        )
    }];

    return <>

        <Form.Item name="players"/>

        <h4 className="participantsCreateHeader">Participants</h4>

        <Space className="gameCreateSelectParticipant" direction={"horizontal"}>
            <Select
                optionFilterProp="label"
                showSearch={true}
                options={players}
                onChange={onPlayerSelected}
                allowClear={true}
                className="gameCreateSelector"
            />
            <Button disabled={isAddButtonDisabled}  onClick={handleAdd}>Add</Button>
        </Space>

        <Table
            bordered
            className="participantsCreateTable"
            dataSource={selectedPlayers}
            columns={columns}
        />

    </>
}

export default Participants;