import axios from "axios";
import {restRoute} from "../AppUtils";
import {getToken} from "../user/auth/Auth";


export function tagsMy() {
    return axios
        .get(restRoute() + '/tags/my', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function deleteTag(tagId) {
    return axios
        .delete(restRoute() + '/tag/delete/' + tagId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function assignUnit(assignInfo) {
    return axios
        .post(restRoute() + '/tag/assign/unit', assignInfo, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function codexFactions() {
    return axios
        .get(restRoute() + '/codex/factions', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function loadUnitsByFaction(faction) {
    return axios
        .get(restRoute() + '/codex/units/' + faction, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
