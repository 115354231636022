import React, {useEffect, useState} from "react";
import {
    Button,
    Form, Input, Modal, Space, Table,
} from "antd";
import {useNavigate} from "react-router-dom";
import {Auth} from "../../../user/auth/Auth";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../../GoogleAnalytics";
import {doRestCall} from "../../../AppUtils";
import {createForce} from "../ForceConnector";
import {notificationCreateFailure, notificationCreateForceSuccess} from "../ForceNotifications";

const Forces = () => {

    GoogleAnalyticsPageView("/crusade/forces", "Crusade Forces")

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const [forces, setForces] = useState([]);

    const [all, setAll] = useState(true);
    const [created, setCreated] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    function onForcesSuccess(response) {
        setForces(response.body)
    }

    useEffect(() => {
        doRestCall( '/crusade/forces', 'get', {
            created: created,
            all: all
        }, null, onForcesSuccess )
    }, [all, created])

    function onAll() {
        setAll(!all)
    }

    function view(id) {
        navigate('/crusade/force/' + id + '/view')
    }

    const columns = [{
        title: 'Name', dataIndex: 'name', key: 'name',
        render: (name, row) => (
            <Button type="link" onClick={() => view(row.id)}>{name}</Button>
        )
    }, {
        title: 'Supply Used', dataIndex: 'supplyUsed', key: 'supplyUsed',
        render: (supplyUsed, row) => (
            <>
                {supplyUsed === 0 ? <p style={{color: "grey"}}>0</p> : <p>{supplyUsed}</p> }
            </>
        )

    }];

    function onCreated() {
        setCreated(!created)
    }

    function handleCancel() {
        setIsModalOpen(false)
    }

    function handleOk() {
      form.validateFields()
          .then(() => {
              form.submit();
              setIsModalOpen(false);
          })
          .catch(() => {

          })
    }

    const save = (force) => {
        doRestCall( '/crusade/force/create', 'post', null, force,
            (response) => {
                if (response.valid) {
                    notificationCreateForceSuccess()
                    navigate('/crusade/force/' + response.body.id + '/view')
                } else {
                    notificationCreateFailure()
                }
            })
    }

    function onFinish(values) {
        save(values)
    }

    return (
        <>
            <Auth/>

            <h2 className="forceViewTableTitles">Forces</h2>

            <Space className="forceListButtons" direction={"horizontal"}>
                <Space.Compact block>
                    <Button
                        onClick={onCreated}
                        className={created ? "onTournButton" : "offTournButton"}
                    >
                        Created by me
                    </Button>
                    <Button onClick={onAll} className={all ? "onGamesButton" : "offGamesButton"}>
                        All
                    </Button>
                </Space.Compact>
{/*
                <Button data-cy="createForce" onClick={() => navigate("/crusade/force/create")}>Create Force</Button>
*/}
                <Button data-cy="createForce" onClick={() => setIsModalOpen(true)}>Create Force</Button>
            </Space>

            <Table
                columns={columns}
                dataSource={forces}
                pagination={true}
                id={"table"}
                style={{width: '100%'}}
                showHeader={true}
                className="forceList"
            />

            <Modal title="Create force" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

                <Form id={"form"}
                      onFinish={onFinish}
                      form={form}
                      style={{width: '100%'}}
                      className="forceCreateForm"
                >

                    <Form.Item
                        label="Name"
                        name="name"
                        className="forceCreateInput"
                        rules={[
                            {
                                required: true,
                                message: 'Please input name!',
                            },
                        ]}
                    >
                        <Input data-cy="name"/>
                    </Form.Item>

                </Form>
            </Modal>
        </>
    );
};

export default Forces;

