import React, {useEffect, useState} from "react";
import {Button, Form, Input, Space, Row, Col, Select} from "antd";
import {codexFactions, createRoster} from "../RosterConnector";
import {useNavigate} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import './styles.css'
import {notificationFailure} from "../RosterNotifications";
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import Units from "./components/Units";
import Factions from "./components/Factions";

const RosterCreate = () => {

    GoogleAnalyticsPageView("/roster/create", "Roster Create")

    const [form] = Form.useForm();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [summary, setSummary] = useState(0);
    const [selectedFaction, setSelectedFaction] = useState();

    const save = (roster) => {
        createRoster(roster)
            .then(response => {
                    if (response.valid) {
                        navigate('/roster/view/' + response.body.id)
                    }
                    if (!response.valid) {
                        response.errors.forEach(error => notificationFailure(error.error))
                    }
                }
            )
            .catch(error => {
                console.log(error)
            })
    }

    function onFinish(values) {
        console.log(values)

        save(values)
    }

    return (
        <>
            <Auth/>

            <p className="rosterCreateTitle">Create New Roster</p>

            <Form id={"form"}
                  onFinish={onFinish}
                  form={form}
                  style={{width: '100%'}}>

                <Row justify="space-around">
                    <Col span={10}>
                        <Form.Item name={"name"} style={{width: '100%'}}>
                            <Space direction={"horizontal"} style={{width: '100%', justifyContent: 'left'}}>
                                <p>Name</p>
                                <Input id={"name"} style={{width: '200%'}}/>
                            </Space>
                        </Form.Item>
                    </Col>

                    <Col span={10}>
                        <Form.Item name={"summary"} style={{width: '100%'}}>
                            <Space style={{width: '100%', justifyContent: 'right'}}>
                                <p>Summary:</p>
                                {summary}
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>

                <Factions setSelectedFaction={setSelectedFaction}/>

                <Units form={form}
                       setSummary={setSummary}
                       selectedFaction={selectedFaction}/>

                <Row justify="space-around" className="rosterCreateButtons">
                    <Col span={10}></Col>

                    <Col span={10}>
                        <Space direction={"horizontal"} style={{width: '100%', justifyContent: 'right'}}>
                            <Button>Cancel</Button>
                            <Button type={"primary"} htmlType={"submit"} onSubmit={onFinish} >Create</Button>
                        </Space>
                    </Col>
                </Row>

            </Form>
        </>
    );
};

export default RosterCreate;

