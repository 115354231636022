import React, {useEffect, useState} from "react";
import {Cascader, InputNumber, Space, Table, Tag, Checkbox, Select, Divider, Typography, Button} from 'antd';
import ModelsTableView from "./ModelsTableView";
import Leader from "./Leader";

const {Text, Link} = Typography;

const Unit = ({unit}) => {

    return (<>
            {unit.models.length > 1 &&
                <>
                    <Text strong>{unit.name}</Text> <Text> {unit.price}</Text><Leader unit={unit}/>
                </>
            }
            <div>
                <ModelsTableView unit={unit}/>
            </div>
        </>
    );

};

export default Unit;






