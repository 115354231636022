import React, {useEffect, useState} from 'react';
import {Collapse, Form, List, Select, Spin} from 'antd';
import {changeHistory} from "../../GameConnector";
import moment from "moment";
import CollapsePanel from "antd/es/collapse/CollapsePanel";

const ChangeHistory = ({targetType, targetId}) => {

    const [changes, setChanges] = useState([])
    const [loading, setLoading] = useState(false)

    const onClick = () => {
        setLoading(true)
        changeHistory(targetType, targetId)
            .then(res => {
                setChanges(res)
                setLoading(false)
            })
    };

    return <></>

/*
    return <>
        {loading && <Spin tip="Loading" size="small"/>}
        <Collapse ghost onChange={onClick}>
            <CollapsePanel key={"122"} header={"Changes History"}>
                {!loading && <List
                    itemLayout="horizontal"
                    dataSource={changes}
                    renderItem={(item, index) => (
                        <List.Item>
                            {item.changeOwnerName} | {item.message} | {moment(item.createdAt).utcOffset(0, true).format('DD MMM YYYY HH:mm')}
                        </List.Item>
                    )}/>}
            </CollapsePanel>
        </Collapse>
    </>
*/
}

export default ChangeHistory;