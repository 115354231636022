import React, {useEffect, useRef, useState} from 'react';
import {Form, Input, Select, Space, Spin, Tabs, Tag} from 'antd';
import {doRestCall} from "../../../AppUtils";
import {useNavigate} from "react-router-dom";
import {PlusOutlined} from '@ant-design/icons';
import {TweenOneGroup} from 'rc-tween-one';
import TournamentTags from "./TournamentTags";


const Settings = ({tournamentId}) => {

    const items = [
        {
            key: '1',
            label: 'Tags',
            children: <TournamentTags tournamentId={tournamentId}/>,
        },
    ];

    return <>
        <Space direction={"vertical"} style={{width: '100%'}} >
            Settings
            <Tabs defaultActiveKey="1" items={items} />
        </Space>
    </>
}

export default Settings;