import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import {Button, Form, Popover, Space, Table} from "antd";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import {useCurrentUserContext} from "../../user/CurrentUserContext";
import EditButton from "./components/EditButton";
import DeleteButton from "./components/DeleteButton";
import Participants from "./components/Participants";

const TournamentCreated = ({tournament}) => {

    GoogleAnalyticsPageView("/tournament/view", "Tournament View")

    const navigate = useNavigate();

    const { currentUser } = useCurrentUserContext();

    const [loading, setLoading] = useState(true)

    function navigateBack() {
        navigate("/tournaments")
    }

    const usersTableColumns = [{
        title: 'Name', dataIndex: 'username', key: 'key', width: '60%',
        render: (_, row) => (row.user?.username)
    },{
        title: 'Faction', dataIndex: 'faction', key: 'faction', width: '40%',
    }];

    return (
        <>
            <Auth/>

            <Participants tournamentId={tournament.id} tournamentPlayers={tournament.players}/>

            { (currentUser?.role === 'ADMIN' || currentUser?.id === tournament.ownerId || currentUser?.tournamentMasterId)
                && (
                    <div className="tournViewButtons">
                        <Button  onClick={navigateBack}>Back</Button>
                        <div>
                            <Space direction={"horizontal"}>
                                <EditButton tournamentId={tournament.id} ownerId={tournament.ownerId} tournamentMasterId={tournament.tournamentMasterId}  />
                                <DeleteButton tournamentId={tournament.id} ownerId={tournament.ownerId} />
                            </Space>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default TournamentCreated;

