import axios from "axios";
import {getToken} from "../user/auth/Auth";
import {restRoute} from "../AppUtils";
import { saveAs } from 'file-saver'

export function codexFactions() {
    return axios
        .get(restRoute() + '/codex/factions', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function findLeaders(faction, name) {
    return axios
        .get(restRoute() + '/roster/findleaders/' + faction + "/" + name, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });

}

export function createRoster(roster) {
    return axios
        .post(restRoute() + '/roster/create', roster, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function updateRoster(roster) {
    return axios
        .put(restRoute() + '/roster/update', roster, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function calculateSummary(units, faction) {
    return axios
        .post(restRoute() + '/roster/calculate', units, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
            params: {
                faction: faction
            }
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function rostersAll() {
    return axios
        .get(restRoute() + '/rosters/all', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function rostersMy() {
    return axios
        .get(restRoute() + '/rosters/my', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function rosterView(rosterId) {
    return axios
        .get(restRoute() + '/roster/view/' + rosterId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function rosterUpdate(rosterId) {
    return axios
        .get(restRoute() + '/roster/update/' + rosterId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function rosterDelete(rosterId) {
    return axios
        .delete(restRoute() + '/roster/delete/' + rosterId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function rosterExport(rosterId) {
    return axios
        .get(restRoute() + '/roster/export/' + rosterId, {
            responseType: 'blob',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,

            },
        })
        .then((res) => {
            saveAs(res.data, 'file.txt');
        })
        .catch((error) => {
            console.log(error);
        });
}

export function loadUnits(faction) {
    return axios
        .get(restRoute() + '/roster/units/' + faction, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function loadUnitsByFaction() {
    return axios
        .get(restRoute() + '/roster/unitsByFaction', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
