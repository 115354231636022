import {Button, Dropdown, Space, Tag} from "antd";
import React, {useEffect, useState} from "react";
import {findLeaders} from "../../CrusadeRosterConnector";
import {CloseCircleOutlined} from '@ant-design/icons';

const Leader = ({unit, hideCustomize, onLeaderSelected}) => {
    const [items, setItems] = useState([])
    const [unitLeaders, setUnitLeaders] = useState([])
    const [unitLeaderOptions, setUnitLeaderOptions] = useState([])


    useEffect(() => {
        if (unitLeaders === undefined || unitLeaders === null || unitLeaders.length === 0) {
            unit.leaders = []
        } else {
            unit.leaders = unitLeaders
            onLeaderSelected()
        }


    }, [unitLeaders])

    const onClick = ({key}) => {
        setUnitLeaders([...unitLeaders, key])
    };

    const clearLeader = (leader) => {
        setUnitLeaders(unitLeaders.filter(item => item !== leader))
    };


    useEffect(() => {
        if (unit !== undefined) {
            findLeaders(unit.faction, unit.name)
                .then(response => {
                    setUnitLeaderOptions(response.body)
                })
        }
    }, [])

    useEffect(() => {
        let tempItems = []
        if (unitLeaderOptions !== undefined && unitLeaderOptions !== null) {
            for (let i = 0; i < unitLeaderOptions.length; i++) {
                tempItems[i] = {
                    key: unitLeaderOptions[i].name,
                    label: unitLeaderOptions[i].name
                }
            }
        }
        setItems(tempItems)
    }, [unitLeaderOptions])


    return (<>
                <Space wrap>
                    {unitLeaders.map((leader, index) => {
                        return <Tag
                            key={leader}
                            closable={true}
                            closeIcon={<CloseCircleOutlined/>}
                            onClose={() => clearLeader(leader)}>{leader}</Tag>
                    })}
                </Space>

            {(!hideCustomize && unitLeaderOptions !== undefined && unitLeaderOptions !== null && unitLeaderOptions.length !== 0) && <>

             <Dropdown
                menu={{
                    items,
                    onClick,
                }}
            >
                <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        Assign a Leader
                    </Space>
                </a>
            </Dropdown>

                </> }
        </>
    )
}


export default Leader;
