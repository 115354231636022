import {Col, Form, Row, Table} from "antd";
import React, {useEffect, useState} from "react";
import Unit from "./Unit";
import {calculateSummary, loadUnits} from "../../RosterConnector";
import {notificationFailure} from "../../RosterNotifications";
import Loading from "./Loading";


const Units = ({form, setSummary, selectedFaction}) => {

    const [selectionType, setSelectionType] = useState('checkbox');
    const [loading, setLoading] = useState(false)
    const [units, setUnits] = useState();

    const [selectedUnits1, setSelectedUnits1] = useState([]);
    const [selectedUnits2, setSelectedUnits2] = useState([]);
    const [selectedUnits3, setSelectedUnits3] = useState([]);
    const [selectedUnits4, setSelectedUnits4] = useState([]);

    useEffect(() => {
        onUnitsChange()
    }, [selectedUnits1, selectedUnits2, selectedUnits3, selectedUnits4])

    const onUnitsChange = () => {
        if (units !== undefined && units !== null) {
            units.forEach(unit => {
                unit.selected = selectedUnits1.includes(unit.key) || selectedUnits2.includes(unit.key) || selectedUnits3.includes(unit.key) || selectedUnits4.includes(unit.key)
            })

            form.setFieldsValue({units: units});

            calculateSummary(units, selectedFaction)
                .then(response => {
                    if (response.valid) {
                        setSummary(response.body.summary)
                    } else {
                        notificationFailure(response.errors[0].error)
                    }
                })
        }
    }

    useEffect(() => {
        if (selectedFaction !== undefined && selectedFaction !== null) {
            setLoading(true)
            loadUnits(selectedFaction)
                .then(response => {
                        setUnits(response)
                        setLoading(false)
                    }
                )
                .catch(error => {
                    setLoading(false)
                })
        }
    }, [selectedFaction])

    const unitsColumns = [{
        title: 'Unit', dataIndex: 'unit', key: 'unit',
        render: (_, unit) => (
            <>
                <Unit unit={unit} onUnitsChange={onUnitsChange} />
            </>
        )
    }];

    const rowSelection1 = {
        selectedRowKeys: selectedUnits1,
        onChange: (selectedRowKeys, selectedRows) => {
            selectedRows.forEach(selectedRow => {
                selectedRow.selected = true
            })
            setSelectedUnits1(selectedRowKeys)
        }
    };

    const rowSelection2 = {
        selectedRowKeys: selectedUnits2,
        onChange: (selectedRowKeys, selectedRows) => {
            selectedRows.forEach(selectedRow => {
                selectedRow.selected = true
            })
            setSelectedUnits2(selectedRowKeys)
        }
    };

    const rowSelection3 = {
        selectedRowKeys: selectedUnits3,
        onChange: (selectedRowKeys, selectedRows) => {

            selectedRows.forEach(selectedRow => {
                selectedRow.selected = true
            })
            setSelectedUnits3(selectedRowKeys)
        }
    };

    const rowSelection4 = {
        selectedRowKeys: selectedUnits4,
        onChange: (selectedRowKeys, selectedRows) => {
            selectedRows.forEach(selectedRow => {
                selectedRow.selected = true
            })
            setSelectedUnits4(selectedRowKeys)
        }
    };

    if (loading) {
        return <Loading />
    }

    if (!loading) {
        return units && <>
            <Form.Item id={"form-space-formitem"} name={"units"} style={{width: '100%'}} />

            <Row justify="space-around">
                <Col span={10}>
                    <p className="rosterCreateSubTitles">Epic Hero</p>
                    <Table
                        columns={unitsColumns}
                        dataSource={units.filter(unit => unit.keywords !== null && unit.keywords.includes("EPIC_HERO"))}
                        showHeader={false}
                        pagination={false}
                        rowSelection={{
                            type: selectionType,
                            ...rowSelection1
                        }}
                        className="rosterCreateTable"
                    />

                    <div className="columnItemSpacing"></div>

                    <p className="rosterCreateSubTitles">Character</p>
                    <Table
                        columns={unitsColumns}
                        dataSource={units.filter(unit => unit.keywords !== null && !unit.keywords.includes("EPIC_HERO") && unit.keywords.includes("CHARACTER"))}
                        showHeader={false}
                        pagination={false}
                        rowSelection={{
                            type: selectionType,
                            ...rowSelection2
                        }}
                       className="rosterCreateTable"
                    />
                </Col>

                <Col span={10}>
                    <p className="rosterCreateSubTitles"> Battleline</p>
                    <Table
                        columns={unitsColumns}
                        dataSource={units.filter(unit => unit.keywords !== null && !unit.keywords.includes("EPIC_HERO") && !unit.keywords.includes("CHARACTER") && unit.keywords.includes("BATTLELINE"))}
                        showHeader={false}
                        pagination={false}
                        rowSelection={{
                            type: selectionType,
                            ...rowSelection3
                        }}
                        className="rosterCreateTable"
                    />

                    <div className="columnItemSpacing"></div>

                    <p className="rosterCreateSubTitles">Other</p>
                    <Table
                        columns={unitsColumns}
                        dataSource={units.filter(unit => (unit.keywords === null) || (!unit.keywords.includes("EPIC_HERO") && !unit.keywords.includes("CHARACTER") && !unit.keywords.includes("BATTLELINE")))}
                        showHeader={false}
                        pagination={false}
                        rowSelection={{
                            type: selectionType,
                            ...rowSelection4
                        }}
                        className="rosterCreateTable"
                    />
                </Col>
            </Row>
            </>
    }

}


export default Units;