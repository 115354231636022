import React from "react";
import AnonymousUserMainMenu from "./AnonymousUserMainMenu";
import AuthenticatedUserMainMenu from "./AuthenticatedUserMainMenu";
import AuthenticatedAdminMainMenu from "./AuthenticatedAdminMainMenu";
import {useParams, useSearchParams} from "react-router-dom";

const MainMenu = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        searchParams.get('print') !== null ? <></> :
        <>
            <AnonymousUserMainMenu />
            <AuthenticatedUserMainMenu />
            <AuthenticatedAdminMainMenu />
        </>
    );
};

export default MainMenu;

