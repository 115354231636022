import {Col, Form, Row, Table} from "antd";
import React, {useEffect, useState} from "react";
import Card from "./Card";
import {loadCards} from "../../CrusadeRosterConnector";


const Cards = ({roster}) => {

    const [cards1, setCards1] = useState([]);
    const [cards2, setCards2] = useState([]);
    const [cards3, setCards3] = useState([]);
    const [cards4, setCards4] = useState([]);

    const [loading, setLoading] = useState(false)

    const [data, setData] = useState();

    const concat = (...arrays) => [].concat(...arrays.filter(Array.isArray));

    useEffect(() => {
        console.log("Cards useEffect cards", roster)
        setData(roster)
    }, [roster])


    useEffect(() => {
        let cards = concat(cards1, cards2, cards3, cards4);

    }, [cards1, cards2, cards3, cards4])


    const unitsColumns = [{
        title: 'Card', dataIndex: 'card', key: 'card',
        render: (_, row) => (
            <>
               <Card card={row}
                      data={data}
                      setData={setData}/>
           </>
        )
    }];


    if (!loading) {
        return data &&
            <Row justify="space-around">
                <Col span={10}>
                    {data.cards[1] &&
                        <>
                            <p className="rosterViewSubTitles">Epic Hero</p>
                            <Table
                                columns={unitsColumns}
                                dataSource={data.cards[1]}
                                showHeader={false}
                                pagination={false}
                                className="rosterViewTable"
                            />
                        </>
                    }

                    <div className="columnItemSpacing"></div>

                    {data.cards[2] &&
                        <>
                            <p className="rosterViewSubTitles">Character</p>
                            <Table
                                columns={unitsColumns}
                                dataSource={data.cards[2]}
                                showHeader={false}
                                pagination={false}
                                className="rosterViewTable"
                            />
                        </>
                    }
                </Col>

                <Col span={10}>
                    {data.cards[3] &&
                        <>
                            <p className="rosterViewSubTitles">Battleline</p>
                            <Table
                                columns={unitsColumns}
                                dataSource={data.cards[3]}
                                showHeader={false}
                                pagination={false}
                                className="rosterViewTable"
                            />
                        </>
                    }

                    <div className="columnItemSpacing"></div>

                    {data.cards[4] &&
                        <>
                            <p className="rosterViewSubTitles">Other</p>
                            <Table
                                columns={unitsColumns}
                                dataSource={data.cards[4]}
                                showHeader={false}
                                pagination={false}
                                className="rosterViewTable"
                            />
                        </>
                    }
                </Col>
            </Row>
    }

}


export default Cards;