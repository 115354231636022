import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {Button, Form, Popover, Space, Table} from "antd";
import moment from "moment/moment";

import {  Typography } from 'antd';
import {DeleteOutlined} from "@ant-design/icons";
import {doRestCall} from "../../../AppUtils";
const { Text, Link } = Typography;


const Games = ({games}) => {

    const navigate = useNavigate();

    const [items, setItems] = useState([])

    useEffect(() => {
        const temp = [];
        for (let i = 0; i < games?.length; i++) {
            temp[i] = {
                key: games[i].key,
                id: games[i].id,
                name: games[i].name,
                player1: games[i].players[0]?.username,
                player2: games[i].players[1]?.username,
                date: games[i].dateTime,
                players: games[i].players,
                winner: games[i].gameWinnerUsername
            }
        }
        setItems(temp)
    }, [games]);

    const gamesTableColumns = [{
        title: 'Name', dataIndex: 'name', key: 'key', width: '30%',
        render: (_, row) => <>
            <Link onClick={() => navigate('/game/view/' + row.id)}>
                {row.name}
            </Link>
        </>
    }, {
        title: 'Player1', dataIndex: 'player1', key: 'player1', width: '20%',
    }, {
        title: 'Player2', dataIndex: 'player2', key: 'player2', width: '20%',
    }, {
        title: 'Winner', dataIndex: 'winner', key: 'winner', width: '20%',
    }, {
        title: 'Date', dataIndex: 'date', key: 'date', width: '20%',
        render: (_, row) =>  <p>
            {row.date && moment(row.date, 'YYYY-MM-DDThh:mm:ss').format('DD MMM YYYY')}
        </p>
    },
    ];

    return (
        <>
            {(games.length > 0) && <Form.Item name="usersFormItem">
                <Table
                    columns={gamesTableColumns}
                    dataSource={items}
                    showHeader={true}
                />
            </Form.Item>
            }


        </>
    );
};

export default Games;

