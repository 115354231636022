import React, {useEffect, useState} from 'react';
import {Button, Spin} from 'antd';

const Warlord = ({hideCustomize, unit, setWarlord}) => {

    const [character, setCharacter] = useState(false);

    useEffect(() => {
        let isCharacter = unit.keywords !== null && (unit.keywords.includes("EPIC_HERO") || unit.keywords.includes("CHARACTER"))
        setCharacter(isCharacter)
    }, [unit]);

    return <>
        {(!hideCustomize && character) && <Button type="link" onClick={() => setWarlord(!unit.warlord)}>Warlord</Button>}
    </>
}

export default Warlord;