import axios from "axios";
import {restRoute} from "../AppUtils";
import {getToken} from "../user/auth/Auth";


export function restUsage() {
    return axios
        .get(restRoute() + '/system/usage/rest', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
