import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {forceView} from "../ForceConnector";
import {Auth} from "../../../user/auth/Auth";
import {Button, Form, InputNumber, Popconfirm, Space, Table} from "antd";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../../GoogleAnalytics";
import moment from "moment";
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {doRestCall} from "../../../AppUtils";
import DeleteButton from "./components/DeleteButton";
import ManageButton from "./components/ManageButton";

const ForceView = () => {

    GoogleAnalyticsPageView("/crusade/force/view", "Crusade Force View")

    const navigate = useNavigate();

    let {forceId} = useParams();

    const [loading, setLoading] = useState(true)
    const [force, setForce] = useState({});
    const {currentUser} = useCurrentUserContext();

    useEffect(() => {
        load()
    }, [])

    const load = () => {
        forceView(forceId)
            .then(response => {
                setForce(response.body)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    function navigateBack() {
        navigate('/crusade/forces')
    }

    const onPrint = () => {
        navigate('/crusade/force/' + force.id + '/print?print')
    };

    const columns = [{
        title: 'Name', dataIndex: 'name', key: 'name',
    }];

    const gameResultsColumns = [{
        title: 'Date', dataIndex: 'date', key: 'date',
        render: (_, row) => (
            moment(row.date, 'YYYYMMDD').format('DD MMM YYYY')
        )
    }];

    return (
        <>
            <Auth/>

            <p className="forceViewTitle">Force <span data-cy="name">{force.name}</span></p>

            <Space direction="horizontal" className="forceViewStats">
                <Space direction="vertical">
                    <p>Supply Limit</p>
                    <p><span data-cy="supplyLimit">{force.supplyLimit}</span></p>
                </Space>
                <Space direction="vertical">
                    <p>Supply Used</p>
                    <p><span data-cy="supplyUsed">{force.supplyUsed}</span></p>
                </Space>
                <Space direction="vertical">
                    <p>Battle Tally</p>
                    <p><span data-cy="battleTally">{force.battleTally}</span></p>
                </Space>
                <Space direction="vertical">
                    <p>Victories</p>
                    <p><span data-cy="victories">{force.victories}</span></p>
                </Space>
                <Space direction="vertical">
                    <p>Requisition Points</p>
                    <p><span data-cy="reqPoints">{force.reqPoints}</span></p>
                </Space>
            </Space>

            <p className="forceViewTableTitles">Cards</p>
            <Table
                columns={columns}
                dataSource={force.cards}
                pagination={
                    {
                        pageSize: 10
                    }
                }
                id={"table"}
                style={{width: '100%'}}
                showHeader={true}
                bordered={true}
                className="forceViewCardsTable"
            />

            <p>
                <span className="gameListViewLabel">Notes:</span>
                {
                    force.notes
                        ? <span className="gameListViewValueBlack">{force.notes}</span>
                        : <span className="gameListViewValueGrey">&#60;none&#62;</span>
                }
            </p>

            {/*
            <Form.Item
                className="tournEditLabel"
                label="Notes" name="notes" >
                <TextArea rows={6} maxLength={1000} />
            </Form.Item>
*/}

            {/*
            <p className="forceViewTableTitles">Game Results</p>
            {force.gameResults === null && <p>no data</p>}
            {(force.gameResults !== null) && <Table
                columns={gameResultsColumns}
                dataSource={force.gameResults}
                pagination={{
                    pageSize: 10
                }}
                id={"table"}
                style={{width: '100%'}}
                showHeader={true}
                bordered={true}
                className="forceViewResultsTable"
            />}
*/}

            <Space className="forceViewButtons" direction={"horizontal"}>
                <Button onClick={navigateBack}>
                    Back
                </Button>
                <div>
                    {(currentUser.role === 'ADMIN' || currentUser.id === force.ownerId)
                        && <Space direction={"horizontal"}>
                            <Button onClick={onPrint} type={"link"}>Print</Button>
                            <ManageButton ownerId={force.ownerId} forceId={forceId} />
                            <DeleteButton ownerId={force.ownerId} forceId={forceId} />
                        </Space>
                    }
                </div>
            </Space>

        </>
    );
};

export default ForceView;

