import {Col, Form, Row, Table} from "antd";
import React, {useEffect, useState} from "react";
import Card from "./Card";
import Loading from "./Loading";
import {calculateSummary, loadCards} from "../../CrusadeRosterConnector";
import {notificationFailure} from "../../CrusadeRosterNotifications";


const Cards = ({form, setSummary, selectedForce, roster, cards}) => {

    const [selectedRowKeys1, setSelectedRowKeys1] = useState();
    const [selectedRowKeys2, setSelectedRowKeys2] = useState();
    const [selectedRowKeys3, setSelectedRowKeys3] = useState();
    const [selectedRowKeys4, setSelectedRowKeys4] = useState();

    const [selectionType, setSelectionType] = useState('checkbox');

    const [cards1, setCards1] = useState([]);
    const [cards2, setCards2] = useState([]);
    const [cards3, setCards3] = useState([]);
    const [cards4, setCards4] = useState([]);

    const [loading, setLoading] = useState(false)

    const [data, setData] = useState([]);

    const concat = (...arrays) => [].concat(...arrays.filter(Array.isArray));


    useEffect(() => {

        console.log("useEffect cards")

        if (cards !== undefined ) {
            setData(cards)
        }

    }, [cards])


    useEffect(() => {

        if (cards !== undefined) {

            if (cards[1] !== undefined) {
                cards[1].forEach(rosterCard => {
                    let dataFound = data.find(dataCard => dataCard.id === rosterCard.id);
                    if (dataFound === undefined) {
                        setData([...data, rosterCard]);
                    } else {
                        dataFound.warlord = rosterCard.warlord
                    }
                })

                setCards1(cards[1]);
                setSelectedRowKeys1(cards[1].map(card => card.key));

                console.log("cards1 [roster, data] ", cards[1].map(card => card.key))
            }
            if (cards[2] !== undefined) {
                cards[2].forEach(rosterCard => {
                    let dataFound = data.find(dataCard => dataCard.id === rosterCard.id);
                    if (dataFound === undefined) {
                        setData([...data, rosterCard]);
                    } else {
                        dataFound.warlord = rosterCard.warlord
                    }
                })

                setCards2(cards[2]);
                setSelectedRowKeys2(cards[2].map(card => card.key));

                console.log("cards2 [roster, data] ", cards[2].map(card => card.key))
            }
            if (cards[3] !== undefined) {
                cards[3].forEach(rosterCard => {
                    let dataFound = data.find(dataCard => dataCard.id === rosterCard.id);
                    if (dataFound === undefined) {
                        setData([...data, rosterCard]);
                    } else {
                        dataFound.warlord = rosterCard.warlord
                    }
                })

                setCards3(cards[3]);
                setSelectedRowKeys3(cards[3].map(card => card.key));

                console.log("cards3 [roster, data] ", cards[3].map(card => card.key))
            }
            if (cards[4] !== undefined) {
                cards[4].forEach(rosterCard => {
                    let dataFound = data.find(dataCard => dataCard.id === rosterCard.id);
                    if (dataFound === undefined) {
                        setData([...data, rosterCard]);
                    } else {
                        dataFound.warlord = rosterCard.warlord
                    }
                })

                setCards4(cards[4]);
                setSelectedRowKeys4(cards[4].map(card => card.key));

                console.log("cards4 [roster, data] ", cards[4].map(card => card.key))
            }

        }
    }, [roster, data]);


    const onLeaderSelected = () => {
        let cards = concat(cards1, cards2, cards3, cards4);

        form.setFieldsValue({cards: cards});
    }

    const onWarlordSelected = () => {
        let cards = concat(cards1, cards2, cards3, cards4);

        cards.forEach(card => {
            let foundDataItem = data.find(dataItem => dataItem.unit.id === card.unit.id);
            if (foundDataItem !== undefined && foundDataItem !== null) {
                card.unit.warlord = foundDataItem.unit.warlord
            }
        })

        form.setFieldsValue({cards: cards});
    }

    useEffect(() => {
        console.log("useEffect selectedForce", selectedForce)
        if (selectedForce !== undefined && selectedForce !== null) {
            setLoading(false)
            loadCards(selectedForce)
                .then(response => {
                        console.log("loadCards then response", response)
                        setData(response.body)
                        setLoading(false)
                    }
                )
                .catch(error => {
                    setLoading(false)
                })
        }
    }, [selectedForce])

    useEffect(() => {
        let cards = concat(cards1, cards2, cards3, cards4);

        form.setFieldsValue({cards: cards});

        if (selectedForce !== null && selectedForce !== undefined) {
            calculateSummary(cards.map(card => card.id), selectedForce)
                .then(response => {
                    if (response.valid) {
                        setSummary(response.body.summary)
                    } else {
                        notificationFailure(response.errors[0].error)
                    }
                })
        }

    }, [cards1, cards2, cards3, cards4])


    const cardColumns = [{
        title: 'Card', dataIndex: 'card', key: 'card',
        render: (_, row) => (
            <>
                <Card card={row}
                      onLeaderSelected={onLeaderSelected}
                      data={data}
                      setData={setData}
                      onWarlordSelected={onWarlordSelected}/>
            </>
        )
    }];


    const rowSelection1 = {
        selectedRowKeys: selectedRowKeys1,
        onChange: (selectedRowKeys, selectedRows) => {
            setCards1(selectedRows);
            setSelectedRowKeys1(selectedRowKeys)
        }
    };
    const rowSelection2 = {
        selectedRowKeys: selectedRowKeys2,
        onChange: (selectedRowKeys, selectedRows) => {
            setCards2(selectedRows);
            setSelectedRowKeys2(selectedRowKeys)
        }
    };
    const rowSelection3 = {
        selectedRowKeys: selectedRowKeys3,
        onChange: (selectedRowKeys, selectedRows) => {
            setCards3(selectedRows);
            setSelectedRowKeys3(selectedRowKeys)
        }
    };
    const rowSelection4 = {
        selectedRowKeys: selectedRowKeys4,
        onChange: (selectedRowKeys, selectedRows) => {
            setCards4(selectedRows);
            setSelectedRowKeys4(selectedRowKeys)
        }
    };


    if (loading) {
        return <Loading/>
    }

    if (!loading) {
        return data && <Form.Item id={"form-space-formitem"} name={"cards"} style={{width: '100%'}}>
            <Row justify="space-around">
                <Col span={10}>
                    <p className="rosterCreateSubTitles">Epic Hero</p>
                    <Table
                        columns={cardColumns}
                        dataSource={data.filter(card => card.keywords !== null && card.keywords.includes("EPIC_HERO"))}
                        showHeader={false}
                        pagination={false}
                        rowSelection={{
                            type: selectionType,
                            ...rowSelection1
                        }}
                        className="rosterCreateTable"
                    />

                    <div className="columnItemSpacing"></div>

                    <p className="rosterCreateSubTitles">Character</p>
                    <Table
                        columns={cardColumns}
                        dataSource={data.filter(card => card.keywords !== null && !card.keywords.includes("EPIC_HERO") && card.keywords.includes("CHARACTER"))}
                        showHeader={false}
                        pagination={false}
                        rowSelection={{
                            type: selectionType,
                            ...rowSelection2
                        }}
                        className="rosterCreateTable"
                    />
                </Col>

                <Col span={10}>
                    <p className="rosterCreateSubTitles"> Battleline</p>
                    <Table
                        columns={cardColumns}
                        dataSource={data.filter(card => card.keywords !== null && !card.keywords.includes("EPIC_HERO") && !card.keywords.includes("CHARACTER") && card.keywords.includes("BATTLELINE"))}
                        showHeader={false}
                        pagination={false}
                        rowSelection={{
                            type: selectionType,
                            ...rowSelection3
                        }}
                        className="rosterCreateTable"
                    />

                    <div className="columnItemSpacing"></div>

                    <p className="rosterCreateSubTitles">Other</p>
                    <Table
                        columns={cardColumns}
                        dataSource={data.filter(card => (card.keywords === null) || (!card.keywords.includes("EPIC_HERO") && !card.keywords.includes("CHARACTER") && !card.keywords.includes("BATTLELINE")))}
                        showHeader={false}
                        pagination={false}
                        rowSelection={{
                            type: selectionType,
                            ...rowSelection4
                        }}
                        className="rosterCreateTable"
                    />
                </Col>
            </Row>
        </Form.Item>
    }

}


export default Cards;