import React, {useEffect, useState} from "react";
import {Button, Form, Input} from "antd";
import {registerUser} from "../UserConnector";
import {useNavigate} from "react-router-dom";
import "./styles.css"
import {notificationFailure} from "../UserNotifications";
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import Link from "antd/lib/typography/Link";
import {useCurrentUserContext} from "../CurrentUserContext";
import {doRestCall} from "../../AppUtils";
import Errors from "./components/Errors";


const UserRegistration = () => {

    GoogleAnalyticsPageView("/user/registration", "User Registration")

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [errors, setErrors] = useState(undefined)

    const { ref } = useCurrentUserContext();

    useEffect(() => {
        form.setFieldsValue({
            ref: ref
        })
    }, [])


    function onSuccess(response) {
        if (response.valid) {
            navigate('/user/login')
        }
        if (!response.valid) {
            if (!response.valid) {
                setErrors(response.errors)
            }
        }
    }

    function onFinish(values) {
        doRestCall( '/user/register', 'post', {}, {
            username: values.username,
            password: values.password,
            email: values.email,
            ref: values.ref
        }, onSuccess)
    }

    function navigateToLogin() {
        navigate("/user/login")
    }

    return (
        <>
            <Errors  errors={errors} />
            <Form id={"form"}
                onFinish={onFinish}
                form={form}
                className="registerForm"
                layout="vertical"
                >

                <h1 class="signUpHeader">Sign Up</h1>
                <h4 class="signUpSubheader">Please enter your details below</h4>

                <Form.Item name="ref" id="ref" hidden="true">
                    <Input />
                </Form.Item>

                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Please input username!',
                        },
                    ]}
                    label="Username"
                    name="username">
                    <Input placeholder="Input Username" className="registerInput" name="username" data-cy="username"/>
                </Form.Item>

                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Please input email!',
                        },
                    ]}
                    label="Email"
                    name="email">
                    <Input placeholder="Input Email" className="registerInput" name="email" data-cy="email"/>
                </Form.Item>

                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Please input password!',
                        },
                    ]}
                    label="Password"
                    name="password">
                    <Input.Password placeholder="Input Password" className="registerInput" name="password" data-cy="password"/>
                </Form.Item>

                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Please input password!',
                        },
                    ]}
                    label="Confirm Password"
                    name="passwordRepeat">
                    <Input.Password placeholder="Repeat Password" className="registerInput" name="passwordRepeat" data-cy="passwordRepeat"/>
                </Form.Item>

                <p className="hasAccountLabel">
                    Already have an account?
                    <span className="hasAccountAction"><Link onClick={navigateToLogin}>Sign In</Link></span>
                </p>
                <Button htmlType="submit" name="submit" data-cy="submit">
                    Sign Up
                </Button>

            </Form>
        </>
    );
};

export default UserRegistration;

