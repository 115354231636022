import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import './styles.css'
import {GoogleAnalyticsPageView} from "../../../GoogleAnalytics";
import {Auth} from "../../../user/auth/Auth";
import {crusadeRosterDelete, crusadeRosterExport, crusadeRosterView} from "../CrusadeRosterConnector";
import Cards from "./components/Cards";
import {Button, Col, Row, Space} from "antd";


const CrusadeRosterView = () => {

    GoogleAnalyticsPageView("/crusade/roster/view", "Crusade Roster View")


    const navigate = useNavigate();

    let {crusadeRosterId} = useParams();

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({});

    useEffect(() => {
        load()
    }, [])


    const load = () => {
        crusadeRosterView(crusadeRosterId)
            .then(response => {
                setData(response.body)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }


    function editRoster() {
        navigate("/crusade/roster/edit/" + crusadeRosterId)
    }

    function exportRoster() {
        crusadeRosterExport(crusadeRosterId)
    }

    function deleteRoster() {
        crusadeRosterDelete(crusadeRosterId)
    }

    return (
        <>
            <Auth/>
            <p className="rosterViewTitle">View Crusade Roster - {data && data.name}</p>
            <p className="rosterViewTitle">Force: {(data && data.force) && data.force.name}</p>
            {loading && <p>loading</p>}

            {!loading &&
                <>
                    <Cards roster={data} />

                </>}

            <Row justify="space-around">
                <Col span={10}></Col>

                <Col span={10}>
                    <Space style={{width: '100%', justifyContent: 'right'}} className="rosterViewButtons">
                        <Button onClick={deleteRoster} type="primary" danger>Delete</Button>
                        <Button onClick={exportRoster}>Export</Button>
                        <Button onClick={editRoster}>Edit</Button>
                    </Space>
                </Col>
            </Row>
        </>
    );

};

export default CrusadeRosterView;

