import React, {useEffect, useState} from "react";
import {
    Alert,
    Button, Form,
    Table
} from "antd";
import {spoofUser, usersAll} from "../UserConnector";
import {Auth, saveToken} from "../auth/Auth";
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import {useNavigate} from "react-router-dom";
import {useCurrentUserContext} from "../CurrentUserContext";

const UsersAll = () => {

    GoogleAnalyticsPageView("/users/all", "Users All")

    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState({});
    const navigate = useNavigate();
    const { setNeedRefresh } = useCurrentUserContext();
    const [responseErrors, setResponseErrors] = useState([]);

    useEffect(() => {
        load()
    }, [])

    const load = () => {
        usersAll()
            .then(response => {
                setUsers(response.body)
                setLoading(false)

            })
            .catch(error => {
                console.log(error)
            })
    }


    function spoof(id) {
        spoofUser(id)
            .then(response => {
                if (response.valid) {
                    saveToken(response.body.token)
                    navigate('/home')
                    setNeedRefresh(true)
                }
                if (!response.valid) {
                    setResponseErrors(response.errors)
                }
            })
    }



    const columns = [{
        title: 'Id', dataIndex: 'id', key: 'id',
    },{
        title: 'Username', dataIndex: 'username', key: 'username',
    }, {
        title: 'Email', dataIndex: 'email', key: 'email',
    }, {
        title: 'Action',
        key: 'operation',
        render: (_, row) => <Button onClick={() => spoof(row.id)} type="link">Spoof</Button>,
    },];


    return (
        <>
            <Auth />

            {responseErrors && responseErrors.map((responseError, i) => {
                    return <Alert
                        message={responseError.error}
                        type="error"
                        closable
                    />
                }
            )}

            {loading && <p>loading</p>}
            {!loading &&
                <Table
                    columns={columns}
                    dataSource={users}
                    pagination={false}
                    id={"table"}
                    style={{width: '100%'}}
                    showHeader={true}
                />

            }
        </>
    );
};

export default UsersAll;

