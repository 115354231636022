import React, {useEffect} from "react";

import News from "./news/News";
import Footer from "./Footer";
import {GoogleAnalyticsPageView} from "../GoogleAnalytics";
import {useCurrentUserContext} from "../user/CurrentUserContext";
import {useNavigate, useSearchParams} from "react-router-dom";
import {isMobile} from "react-device-detect";


const Home = () => {

    GoogleAnalyticsPageView("/home", "Home")

    const { setRef } = useCurrentUserContext();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setRef(searchParams.get("ref"))
    }, [])

    return (
        <>
            <News />
        </>
    );
};

export default Home;

