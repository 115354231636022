import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import {Button, Form, Popover, Space, Table} from "antd";
import moment from "moment/moment";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import {useCurrentUserContext} from "../../user/CurrentUserContext";
import {doRestCall} from "../../AppUtils";
import EditButton from "./components/EditButton";
import DeleteButton from "./components/DeleteButton";
import Rounds from "./components/Rounds";
import Participants from "./components/Participants";

const TournamentStarted = ({tournament}) => {

    GoogleAnalyticsPageView("/tournament/view", "Tournament View")

    const navigate = useNavigate();

    const [players, setPlayers] = useState([]);

    const { currentUser } = useCurrentUserContext();



    const onBoard = () => {
        navigate("/tournament/board/" + tournament.id)
    };

    function navigateBack() {
        navigate("/tournaments")
    }

    return (
        <>
            <Auth />
                <Space direction="vertical" className="tournView">

                    <Participants tournamentId={tournament.id} tournamentPlayers={tournament.players}/>

                    <Rounds tournamentId={tournament.id} rounds={tournament.rounds} />

                    <Space className="tournViewButtons" direction={"horizontal"}>
                        <Button onClick={navigateBack}>
                            Back
                        </Button>

                        <div>
                            <Button onClick={onBoard}>Board</Button>
                            <Space direction={"horizontal"}>
                                <EditButton tournamentId={tournament.id} ownerId={tournament.ownerId} tournamentMasterId={tournament.tournamentMasterId}  />
                                <DeleteButton tournamentId={tournament.id} ownerId={tournament.ownerId} />
                            </Space>
                        </div>
                    </Space>
                </Space>

         </>
    );
};

export default TournamentStarted;

