import React, {useEffect, useState} from "react";
import {Cascader, InputNumber, Space, Table, Tag, Checkbox, Select, Divider, Typography, Button, Dropdown} from 'antd';
import "../styles.css"

import Prices from "./Prices";
import Count from "./Count";
import Weapons from "./Weapons";

const {Text, Link} = Typography;

const UnitMultipleModels = ({unit, onUnitsChange, hideCustomize}) => {

    const columns = [{
        title: 'Name', dataIndex: 'name', key: 'name',
        render: (name, model) => (
            <Space direction="vertical">
                <Text strong className="rosterCreateChildName">{name}</Text>
                <Weapons model={model} onUnitsChange={onUnitsChange} hideCustomize={hideCustomize}/>
            </Space>
        )
    }, {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
        align: 'right',
        render: (_, model) => (
            <>
                <Count model={model} onUnitsChange={onUnitsChange}/>
            </>
        )
    }];

    return (
        <>
            <Text strong className="rosterCreateParentName">{unit.name}</Text>
            <Prices prices={unit.prices}/>
            <Table
                columns={columns}
                dataSource={unit.models}
                pagination={false}
                id={"table"}
                style={{width: '100%'}}
                showHeader={false}
                rowClassName={'rosterCreateRow'}
            />
        </>);

};

export default UnitMultipleModels;













