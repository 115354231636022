import React, {useEffect, useState} from "react";
import {
    Button,
    InputNumber, Space,
    Table, 
    Row, Col
} from "antd";
import "./styles.css"

import {useLocation, useNavigate, useParams} from "react-router-dom";
import {rosterDelete, rosterExport, rosterView} from "../RosterConnector";
import {Auth} from "../../user/auth/Auth";
import Unit from "../create/components/Unit";
import UnitView from "./components/UnitView";
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";

const RosterView = () => {

    GoogleAnalyticsPageView("/roster/view", "Roster View")

    const navigate = useNavigate();

    let {rosterId} = useParams();

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({});

    useEffect(() => {
        load()
    }, [])


    const load = () => {
        rosterView(rosterId)
            .then(response => {
                setData(response.body)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const unitsColumns = [{
        title: 'Unit', dataIndex: 'unit', key: 'unit',
        render: (_, row) => (
            <>
                <UnitView unit={row} />
            </>
        )
    }];


    function editRoster() {
        navigate("/roster/edit/" + rosterId)
    }

    function exportRoster() {
        rosterExport(rosterId)
    }

    function deleteRoster() {
        rosterDelete(rosterId)
    }

    return (
        <>
            <Auth/>
            <p className="rosterViewTitle">View Roster - {data && data.faction} - {data && data.name}</p>
            {loading && <p>loading</p>}
            {!loading &&
                <>
                  <Row justify="space-around" className="rosterViewSummary">
                    <Col span={10}></Col>

                    <Col span={10}>
                      <Space style={{width: '100%', justifyContent: 'right'}}>
                        Summary: {data.summary}
                      </Space>
                    </Col>
                  </Row>

                  <Row justify="space-around">
                    <Col span={10}>
                      {data.units[1] &&
                          <>
                              <p className="rosterViewSubTitles">Epic Hero</p>
                              <Table
                                  columns={unitsColumns}
                                  dataSource={data.units[1]}
                                  showHeader={false}
                                  pagination={false}
                                  className="rosterViewTable"
                              />
                          </>
                      }

                      <div className="columnItemSpacing"></div>

                      {data.units[2] &&
                          <>
                              <p className="rosterViewSubTitles">Character</p>
                              <Table
                                  columns={unitsColumns}
                                  dataSource={data.units[2]}
                                  showHeader={false}
                                  pagination={false}
                                  className="rosterViewTable"
                                  />
                          </>
                      }
                    </Col>

                    <Col span={10}>
                      {data.units[3] &&
                          <>
                              <p className="rosterViewSubTitles">Battleline</p>
                              <Table
                                  columns={unitsColumns}
                                  dataSource={data.units[3]}
                                  showHeader={false}
                                  pagination={false}
                                  className="rosterViewTable"
                              />
                          </>
                      }

                      <div className="columnItemSpacing"></div>

                      {data.units[4] &&
                          <>
                              <p className="rosterViewSubTitles">Other</p>
                              <Table
                                  columns={unitsColumns}
                                  dataSource={data.units[4]}
                                  showHeader={false}
                                  pagination={false}
                                  className="rosterViewTable"
                              />
                          </>
                      }
                      </Col>
                    </Row>
                  
                    <Row justify="space-around">
                      <Col span={10}></Col>

                      <Col span={10}>
                        <Space style={{width: '100%', justifyContent: 'right'}} className="rosterViewButtons">
                          <Button onClick={deleteRoster} type="primary" danger>Delete</Button>
                          <Button onClick={exportRoster}>Export</Button>
                          <Button onClick={editRoster}>Edit</Button>
                        </Space>
                      </Col>
                    </Row>
                </>


            }
        </>
    );

};

export default RosterView;

