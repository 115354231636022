import React from 'react';
import {Spin} from 'antd';

const Loading = () => {

    return <>
        <br/><br/><br/>
        <br/><br/><br/>
        <Spin size="large" />
    </>
}

export default Loading;