import axios from "axios";
import {getToken} from "./auth/Auth";
import {restRoute} from "../AppUtils";
import {notificationFailure} from "./UserNotifications";


export function usersAll() {
    return axios
        .get(restRoute() + '/users/all', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

/*
export function registerUser(username, password, email, ref) {
    return axios
        .post(restRoute() + '/user/register', {
            username: username,
            password: password,
            email: email,
            ref: ref,
        }, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
*/

/*
export function update(id, username, password, email) {
    return axios
        .put(restRoute() + '/user/update', {
            id: id,
            username: username,
            password: password,
            email: email
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
*/

/*
export function loginUser(username, password) {


/!*
    const { doCall } = useRestCall();

    return doCall('/user/login', 'post', {}, {
        username: username,
        password: password,
    });
*!/

/!*
    return axios
        .post(restRoute() + '/user/login', {
            username: username,
            password: password,
        }, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
*!/
}
*/

/*
export function createUser(user) {

    return axios
        .post(restRoute() + '/user/create', user, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
*/

export function spoofUser(userId) {

    return axios
        .post(restRoute() + '/user/spoof', userId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

/*
export function me() {
    return axios
        .get(restRoute() + '/user/me', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
*/
