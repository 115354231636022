import React from 'react';
import {Spin, Typography} from 'antd';
import { v4 as uuidv4 } from 'uuid';

const Prices = ({price}) => {

    const {Text, Link} = Typography;

    return <>
        <Text type="secondary" style={{color: "silver"}} className="rosterCreateRowSpacing" key={uuidv4()}>
            Price: {price}
        </Text>
    </>
}

export default Prices;