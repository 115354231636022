import React from "react";
import moment from "moment/moment";

const Health = () => {

    return (
        <>
            <p>{moment(moment.now()).format('DD MMM YYYY HH:mm:ss')}</p>
        </>
    );
};

export default Health;

