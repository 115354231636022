import {Button, Dropdown, Space} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import React from "react";

const Leader = ({unit}) => {
    return (<>
            {(unit.leaders && unit.leaders.length > 1) && <> Leaders: {unit.leaders.join(", ")}</>}
            {(unit.leaders && unit.leaders.length === 1) && <> Leader: {unit.leaders}</>}
        </>
    )
}


export default Leader;
