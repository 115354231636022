import React, {useEffect, useState} from "react";
import {Cascader, InputNumber, Space, Table, Tag, Checkbox, Select, Button, Typography} from 'antd';

const Weapons = ({model, onUnitsChange, hideCustomize}) => {

    const [showDetails, setShowDetails] = useState(false)

    const [selectedRowKeys, setRowKeys] = useState(model.weapons
        .filter(item => (item.defaultWeapon || item.selected))
        .map(item => item.key));

    const {Text, Link} = Typography;

    useEffect(() => {

        let selected = model.weapons.find(weapon => weapon.selected)

        if (selected === undefined) {
            model.weapons.forEach(weapon => {
                weapon.selected = weapon.defaultWeapon
            })
        }
    }, []);

    const onCountChange = (newValue, row) => {
        let objFromArr = model.weapons.find(obj => {
            return obj.key === row.key
        });

        objFromArr.count = newValue

        onUnitsChange()
    };

    function onCustomizeClicked() {
        setShowDetails(true)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            if (selectedRowKeys.length == 0) {
                model.weapons.forEach(weapon => weapon.selected = false)
            } else {
                model.weapons.forEach(weapon => {
                    weapon.selected = selectedRowKeys.includes(weapon.key);
                })
            }
            setRowKeys(model.weapons.filter(item => item.selected).map(item => item.key));
            onUnitsChange();

        },

        getCheckboxProps: (record) => ({
            name: record.name,
        }),

    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: 'Price', dataIndex: 'price', key: 'price',
        }, {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            render: (count, row) => (<InputNumber min={0} max={100} defaultValue={count}
                                                  onChange={(newValue) => onCountChange(newValue, row)}/>)
        }
    ];

    return (
        <>
            {!showDetails &&
                <>
                    <Text type="secondary">
                        {model.weapons
                            .filter(weapon => weapon.defaultWeapon || weapon.selected)
                            .map(weapon => weapon.name)
                            .join(", ")}
                    </Text>
                    {!hideCustomize && <Button type="link" onClick={() => onCustomizeClicked()}>Customize</Button>}
                </>
            }
            {showDetails && <Table
                columns={columns}
                dataSource={model.weapons}
                pagination={false}
                id={"table"}
                style={{width: '100%'}}
                size={"small"}
                showHeader={false}
                rowSelection={{
                    type: 'checkbox', ...rowSelection,
                }}
            />}
        </>
    );

};

export default Weapons;






