import React, {useEffect, useState} from "react";
import {
    Button,
    InputNumber, Space, Switch,
    Table
} from "antd";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import Status from "./components/Status";
import {doRestCall} from "../../AppUtils";

const Tournaments = () => {

    GoogleAnalyticsPageView("/tournaments/my", "My Tournaments")

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [tournaments, setTournaments] = useState({});

    const [created, setCreated] = useState(false);
    const [master, setMaster] = useState(false);
    const [participated, setParticipated] = useState(false);
    const [all, setAll] = useState(true);

    useEffect(() => {
        load()
    }, [])

    useEffect(() => {
        load()
    }, [created, master, participated, all])


    function onLoadSuccess(response) {
        setTournaments(response.body)
        setLoading(false)
    }

    const load = () => {

        doRestCall( '/tournaments', 'get',
            {
                created: created,
                master: master,
                participated: participated,
                all: all
            }, null, onLoadSuccess)

    }

    function view(id) {
        navigate('/tournament/view/' + id)
    }

    const columns = [
        {
            title: 'Name', dataIndex: 'name', key: 'name', width: '40%',
            render: (name, row) => (
                <Button type="link" onClick={() => view(row.id)}>{name}</Button>
            )
        },
        {
            title: 'Universe', dataIndex: 'universe', key: 'universe',  width: '20%',
        },
        {
            title: 'Participants', dataIndex: 'participants', key: 'participants',  width: '15%',
            render: (_, row) => (
                <p>{row.participantsCount} / {row.participantsLimit ? row.participantsLimit : "no limit"}</p>
            )
        },
       {
            title: 'Status', dataIndex: 'status', key: 'status',  width: '25%',
            render: (status, row) => (
                <Status status={status} />
            )
        }
    ];

    function onCreate() {
        navigate('/tournament/create')
    }

    function onCreated() {
        setCreated(!created)
    }

    function onMaster() {
        setMaster(!master)
    }

    function onParticipated() {
        setParticipated(!participated)
    }

    function onAll() {
        setAll(!all)
    }

    return (
        <>
            <Auth/>
            {loading && <p>loading</p>}
            {!loading &&
                <>
                    <h2 className="tournListHeader">Tournaments</h2>

                    <Space className="tournListButtons" direction={"horizontal"} >
                        <Space.Compact block>
                            <Button
                                onClick={onCreated}
                                className={created ? "onTournButton" : "offTournButton"}
                            >
                                Created by me
                            </Button>
                            <Button
                                onClick={onMaster}
                                className={master ? "onTournButton" : "offTournButton"}
                            >
                                Im master
                            </Button>
                            <Button
                                onClick={onParticipated}
                                className={participated ? "onTournButton" : "offTournButton"}
                            >
                                Im participated
                            </Button>
                            <Button
                                onClick={onAll}
                                className={all ? "onTournButton" : "offTournButton"}
                            >
                                All
                            </Button>
                        </Space.Compact>
                        <Button data-cy="createTournament" onClick={onCreate} >Create Tournament</Button>
                    </Space>

                    <Table
                        columns={columns}
                        dataSource={tournaments}
                        pagination={true}
                        id={"table"}
                        style={{width: '100%', padding: '0 3rem'}}
                        showHeader={true}
                        className="tournList"
                    />
                </>
            }
        </>
    );
};

export default Tournaments;

