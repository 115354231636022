import React from "react";
import {FacebookOutlined, MailOutlined, TwitchOutlined, DollarOutlined, GoogleOutlined, DiscordOutlined} from '@ant-design/icons';
import {useNavigate, useSearchParams} from "react-router-dom";
import {Affix, Button, FloatButton, Image, Space, Typography} from "antd";
import {useCurrentUserContext} from "../user/CurrentUserContext";
import {GoogleAnalyticsPageView} from "../GoogleAnalytics";
import HelpButton from "./HelpButton";

const { Text } = Typography;

const Footer = ({queryParams}) => {
    const navigate = useNavigate();
    const { currentUser } = useCurrentUserContext();
    const [searchParams, setSearchParams] = useSearchParams();

    function openLink(link) {
        window.open(link, '_blank').focus();
    }

    return (
        <>
            {searchParams.get('print') !== null && <p style={{color: "lightgray"}}>https://SmartRoster.club</p>}
            {searchParams.get('print') === null &&
            <Affix offsetBottom={0} >
                <HelpButton />
                <Space style={{backgroundColor: "white", width: '100%'}} align="center" direction="horizontal" >
                    <Button type="text"
                            onClick={(e) => {
                                e.preventDefault()
                                GoogleAnalyticsPageView("Footer: GoFund", "Footer: GoFund")
                                openLink('https://gofund.me/e0aa6bc5')
                            }}><DollarOutlined /></Button>
                    <Button type="text"
                            onClick={(e) => {
                                e.preventDefault()
                                GoogleAnalyticsPageView("Footer: Facebook", "Footer: Facebook")
                                openLink('https://www.facebook.com/groups/824327146381514')
                            }}><FacebookOutlined/></Button>
                    <Button type="text"
                            onClick={(e) => {
                                e.preventDefault()
                                GoogleAnalyticsPageView("Footer: VK", "Footer: VK")
                                openLink('https://vk.com/smartroster')
                            }}><Image src="/logo_vk.png" preview={false} /></Button>
                    <Button type="text"
                            onClick={(e) => {
                                e.preventDefault()
                                GoogleAnalyticsPageView("Footer: Twitch", "Footer: Twitch")
                                openLink('https://www.twitch.tv/smartroster')
                            }}><TwitchOutlined /></Button>
                    <Button type="text"
                            onClick={(e) => {
                                e.preventDefault()
                                GoogleAnalyticsPageView("Footer: GoogleGroups", "Footer: GoogleGroups")
                                openLink('https://groups.google.com/g/smartroster')
                            }}><GoogleOutlined /></Button>
                    <Button type="text"
                            onClick={(e) => {
                                e.preventDefault()
                                GoogleAnalyticsPageView("Footer: Discord", "Footer: Discord")
                                openLink('https://discord.gg/hEUbeTvhZn')
                            }}><DiscordOutlined /></Button>
                    <Button type="text"
                            onClick={(e) => {
                                e.preventDefault()
                                GoogleAnalyticsPageView("Footer: MailTo", "Footer: MailTo")
                                window.location.href = "mailto:alex@smartroster.club"
                            }}><MailOutlined/></Button>
                    <Text disabled>Env: {process.env.REACT_APP_ENV}</Text>
                    <Text disabled>Build #: {process.env.REACT_APP_BUILD}</Text>
                    <Text disabled>Revision: {process.env.REACT_APP_REVISION}</Text>
                    {currentUser && <Text disabled>User: {currentUser && currentUser.username}</Text>}
                </Space>
            </Affix>
            }
        </>
    );
};

export default Footer;

