import React, {useEffect, useState} from "react";
import {Cascader, InputNumber, Space, Table, Tag, Checkbox, Form, Button} from 'antd';
import {Typography} from 'antd';
import Weapons from "./Weapons";

const {Text, Link} = Typography;

const ModelsTable = ({unit}) => {

    const [data, setData] = useState(unit.models)

    useEffect(() => {
        if (unit.models.length > 1) {
            unit.models.unshift({
                "id": 'id' + (new Date()).getTime(),
                "key": 'id' + (new Date()).getTime(),
                "name": unit.name,
                "weapons": []
            })
            setData(unit.models)
        }
    }, [])

    const columns = [{
        title: 'Name', dataIndex: 'name', key: 'name',
        render: (name, model) => (
            <Space direction="vertical">
                <Text strong>
                    {unit.warlord && <Text code>WARLORD</Text>}
                    {unit.models.length === 1 && name}
                    {unit.models.length !== 1 && <Text className="rosterViewChildName">{name}</Text>}
                    {unit.models.length === 1 &&
                        <Text type="secondary" style={{color: "silver"}} className="rosterViewRowSpacing">{unit.price}</Text>}
                </Text>
                <Weapons model={model} />
            </Space>
        )
    }, {
        title: 'Price', dataIndex: 'price', key: 'price',
    }, {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
        align: 'right',
        render: (count, model) => (
            <p>{model.count} </p>
        )

    }];

    return (<Table
        columns={columns}
        dataSource={data}
        pagination={false}
        id={"table"}
        style={{width: '100%'}}
        showHeader={false}
        rowClassName={'rosterViewRow'}
    />);

};

export default ModelsTable;






