import React from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Popconfirm} from "antd";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";

const DeleteButton = ({ forceId, ownerId }) => {

    const { currentUser } = useCurrentUserContext();
    const navigate = useNavigate();

    const onManage = () => {
        navigate('/crusade/force/' + forceId + '/manage')
    };

    return <>
        {(currentUser?.role === 'ADMIN' || currentUser?.id === ownerId)
&&  <Button data-cy="manage" onClick={onManage}>Manage</Button>}
    </>
}

export default DeleteButton;