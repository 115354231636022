import React, {useState} from "react";
import {Alert, Button, Form, Input} from "antd";
import {loginUser} from "../UserConnector";
import {useNavigate} from "react-router-dom";
import {saveToken} from "../auth/Auth";
import "./styles.css"
import {useCurrentUserContext} from "../CurrentUserContext";
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import Link from "antd/lib/typography/Link";
import {doRestCall} from "../../AppUtils";
import {isMobile} from "react-device-detect";

const UserLogin = () => {

    GoogleAnalyticsPageView("/user/login", "User Login")

    const navigate = useNavigate();

    const [form] = Form.useForm();
    const { setNeedRefresh } = useCurrentUserContext();

    const [responseErrors, setResponseErrors] = useState([]);

    function onSuccess(response) {
        if (response.valid) {
            saveToken(response.body.token)
            navigate('/home')
            setNeedRefresh(true)
        }
        if (!response.valid) {
            setResponseErrors(response.errors)
        }
    }

    function onFinish(values) {
        doRestCall('/user/login', 'post', {}, {
            username: values.username,
            password: values.password,
        }, onSuccess)
    }

    function navigateToRegistration() {
        navigate("/user/registration")
    }

    return (
        <>
            {isMobile && navigate('/mobilenotsupported')}

            {responseErrors && responseErrors.map((responseError, i) => {
                    return <Alert
                        message={responseError.error}
                        type="error"
                        closable
                    />
                }
            )}
          
          <div>
            <Form id={"form"}
                onFinish={onFinish}
                form={form}
                className="loginForm"
                layout="vertical"
                >

                <h1 className="signInHeader">Sign In</h1>
                <h4 className="signInSubheader">Please enter your details below</h4>

                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Please input username!',
                        },
                    ]}
                    label="Username"
                    name="username">
                    <Input placeholder="Input Username" className="loginInput" name="username" data-cy="username"/>
                </Form.Item>

                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Please input password!',
                        },
                    ]}
                    label="Password"
                    name="password"
                    className="passwordItem">
                    <Input.Password placeholder="Input Password" className="loginInput" name="password" data-cy="password" />
                </Form.Item>
                <Link className="forgotPassword" disabled={'true'}>Forgot password?</Link>

                <p className="noAccountLabel">
                    Don't have an account?
                    <span className="noAccountAction"><Link onClick={navigateToRegistration}>Sign Up</Link></span>
                </p>
                <Button htmlType="submit"  data-cy="submit">
                    Sign In
                </Button>

            </Form>
          </div>
        </>
    );
};

export default UserLogin;

