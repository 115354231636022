import ReactGA from "react-ga4";
import axios from "axios";
import {env, restRoute} from "./AppUtils";



export function GoogleAnalyticsPageView(page, title) {
    if (env() === 'PROD') {
        ReactGA.send({hitType: "pageview", page: page, title: title});
    }
}
