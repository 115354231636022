import React from 'react';
import {List, Space, Typography} from 'antd';
const { Text, Link } = Typography;

const Errors = ({errors}) => {

    return <>
        { errors && <Space direction="vertical" style={{height: '100%'}} ><List
            size="small"
            dataSource={errors}
            renderItem={(error) => <List.Item><Text type={"danger"}><span data-cy="error">{error.error}</span></Text></List.Item>}
        /> </Space>
        }
    </>
}

export default Errors;