import React, {useEffect, useState} from "react";
import {rostersAll} from "../RosterConnector";
import {Button, Table} from "antd";
import {Auth} from "../../user/auth/Auth";
import {useNavigate} from "react-router-dom";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";


const RostersAll = () => {

    GoogleAnalyticsPageView("/rosters/all", "Rosters All")

    const [loading, setLoading] = useState(true)
    const [rosters, setRosters] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        load()
    }, [])

    const load = () => {

        rostersAll()
            .then(response => {
                setRosters(response.body)
                setLoading(false)

            })
            .catch(error => {
                console.log(error)
            })

    }

    function view(id) {
        navigate('/roster/view/' + id)
    }

    const columns = [{
        title: 'Name', dataIndex: 'name', key: 'name',
        render: (name, row) => (
            <Button type="link" onClick={() => view(row.id)}>{name}</Button>
        )
    }];


    return (
        <>
            <Auth/>

            {loading && <p>loading</p>}

            {!loading &&
              <>
                <p className="rosterListTitle">My Rosters</p>
                <Table
                    columns={columns}
                    dataSource={rosters}
                    pagination={false}
                    id={"table"}
                    style={{width: '100%'}}
                    showHeader={false}
                    className="rosterList"
                />
              </>

            }

        </>
    );
};

export default RostersAll;

