import React, {useEffect, useState} from "react";
import {Cascader, InputNumber, Space, Table, Tag, Checkbox, Select, Divider, Typography, Button, Dropdown} from 'antd';
import "../styles.css"

import Prices from "./Prices";
import Weapons from "./Weapons";
import Count from "./Count";

const {Text, Link} = Typography;

const UnitSingleModel = ({unit, onUnitsChange, hideCustomize}) => {

    return (
        <>
            <Space direction="vertical">
                <Space>
                    <Text strong className="rosterCreateParentName">{unit.name}</Text>
                    <Prices prices={unit.prices}/>
                    <Count model={unit.models[0]} onUnitsChange={onUnitsChange}/>
                </Space>
                <Space>
                    <Weapons model={unit.models[0]} onUnitsChange={onUnitsChange} hideCustomize={hideCustomize}/>
                </Space>
            </Space>
        </>
    );

};

export default UnitSingleModel;













