import React, {useEffect, useState} from "react";
import {
    Button,
    Table
} from "antd";

import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import {deleteTag, tagsMy} from "../TagConnector";
import {Auth} from "../../user/auth/Auth";
import {useNavigate} from "react-router-dom";

const TagsMy = () => {

    GoogleAnalyticsPageView("/tags/my", "Tags My")

    const [loading, setLoading] = useState(true)
    const [tags, setTags] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        load()
    }, [])

    const load = () => {
        tagsMy()
            .then(response => {
                setTags(response.body)
                setLoading(false)

            })
            .catch(error => {
                console.log(error)
            })
    }


    function onDeleteTag(id) {
        deleteTag(id)
            .then(response => {
                load()
            })
            .catch(error => {
                console.log(error)
            })

    }

    const columns = [{
        title: 'Id', dataIndex: 'id', key: 'id',
        render: (id, row) => (
            <Button type="link">{id}</Button>
        )
    }, {
        title: 'Faction', dataIndex: 'unitFaction', key: 'unitFaction',
    }, {
        title: 'Name', dataIndex: 'unitName', key: 'unitName',
    }, {
        title: 'Actions', dataIndex: 'actions', key: 'actions',
        render: (_, row) => (
            <Button type="link" onClick={() => onDeleteTag(row.id)}>Delete</Button>
        )
    }
    ];


    return (
        <>
            <Auth/>
            {loading && <p>loading</p>}
            {!loading &&
                <Table
                    columns={columns}
                    dataSource={tags}
                    pagination={false}
                    id={"table"}
                    style={{width: '100%'}}
                    showHeader={true}
                />

            }
        </>
    );
};

export default TagsMy;

