import React, {useEffect, useState} from "react";
import {
    FacebookOutlined,
    MailOutlined,
    TeamOutlined,
    DollarOutlined,
    GoogleOutlined,
    DiscordOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';
import {useNavigate, useSearchParams} from "react-router-dom";
import {Affix, Button, FloatButton, Input, Modal, Space, Typography} from "antd";
import {useCurrentUserContext} from "../user/CurrentUserContext";
import {isMobile} from "react-device-detect";
import {GoogleAnalyticsPageView} from "../GoogleAnalytics";
import Icon from "antd/es/icon";
import {doRestCall} from "../AppUtils";

const {Text} = Typography;
const {TextArea} = Input;

const HelpButton = ({queryParams}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const [nameErrorStatus, setNameErrorStatus] = useState()
    const [nameEmailStatus, setEmailErrorStatus] = useState()
    const [nameMessageStatus, setMessageErrorStatus] = useState()

    const showModal = () => {
        reset()
        setIsModalOpen(true);
    };

    useEffect(() => {
        if (!name) {
            setNameErrorStatus("error")
        }
        if (name) {
            setNameErrorStatus("")
        }
    }, [name])

    useEffect(() => {
        if (!email) {
            setEmailErrorStatus("error")
        }
        if (email) {
            setEmailErrorStatus("")
        }
    }, [email])

    useEffect(() => {
        if (!message) {
            setMessageErrorStatus("error")
        }
        if (message) {
            setMessageErrorStatus("")
        }
    }, [message])

    const handleOk = () => {
        if (name && email && message) {
            doRestCall('/feedback', 'post', null, {
                name: name,
                email: email,
                text: message})
            reset()
            setIsModalOpen(false);
        }
    };

    function reset() {
        setName("")
        setEmail("")
        setMessage("")

        setNameErrorStatus("")
        setEmailErrorStatus("")
        setMessageErrorStatus("")
    }

    const handleCancel = () => {
        reset();

        setIsModalOpen(false);
    };

    return (
        <>
            <Modal title="Feedback" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Space direction={"vertical"}>
                    <Space direction={"horizontal"}>
                        <Input placeholder={"Name"}
                               status={nameErrorStatus}
                               onChange={(e) => setName(e.target.value)}/>
                        <Input placeholder={"Email"}
                               onChange={(e) => setEmail(e.target.value)}/>
                    </Space>
                    <TextArea rows={6} maxLength={1000} placeholder={"Message"}
                              onChange={(e) => setMessage(e.target.value)}/>

                </Space>
            </Modal>

            <FloatButton icon={<InfoCircleOutlined/>}
                         tooltip={"Feedback"}
                         onClick={() => showModal()}/>
        </>
    );
};

export default HelpButton;

