import React, {useEffect, useState} from "react";
import {Button, Form, Input, Space, Row, Col, Select, Table} from "antd";
import {useNavigate} from "react-router-dom";
import './styles.css'
import {GoogleAnalyticsPageView} from "../../../GoogleAnalytics";
import {Auth} from "../../../user/auth/Auth";
import {rostersMy} from "../../../roster/RosterConnector";
import {crusadeRostersMy} from "../CrusadeRosterConnector";


const CrusadeRostersMy = () => {

    GoogleAnalyticsPageView("/crusade/rosters/my", "Crusade Rosters My")


    const [loading, setLoading] = useState(true)
    const [rosters, setRosters] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        load()
    }, [])

    const load = () => {

        crusadeRostersMy()
            .then(response => {
                setRosters(response.body)
                setLoading(false)

            })
            .catch(error => {
                console.log(error)
            })

    }


    function view(id) {
        navigate('/crusade/roster/view/' + id)
    }

    const columns = [{
        title: 'Name', dataIndex: 'name', key: 'name',
        render: (name, row) => (
            <Button type="link" onClick={() => view(row.id)}>{name}</Button>
        )
    }];


    return (
        <>
            <Auth/>

            {loading && <p>loading</p>}

            {!loading &&
                <>
                    <p className="rosterListTitle">My Crusade Rosters</p>
                    <Table
                        columns={columns}
                        dataSource={rosters}
                        pagination={false}
                        id={"table"}
                        style={{width: '100%'}}
                        showHeader={false}
                        className="rosterList"
                    />
                </>

            }

        </>
    );

};

export default CrusadeRostersMy;

