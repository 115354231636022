import React, {useEffect, useState} from 'react';
import {Form, Select, Spin} from 'antd';
import {forcesMy} from "../../CrusadeRosterConnector";

const Forces = ({onForceSelected, onForcesLoaded}) => {
    const [forceOptions, setForceOptions] = useState([])
    const [forces, setForces] = useState([]);

    useEffect(() => {

        if (forces !== undefined && forces !== null) {
            let temp = []
            for (let i = 0; i < forces.length; i++) {
                temp[i] = {
                    value: forces[i].id,
                    label: forces[i].name,
                }
            }
            setForceOptions(temp)
        }

    }, [forces]);

    useEffect(() => {
        forcesMy()
            .then(response => {
                    setForces(response.body)
                    onForcesLoaded()
                }
            )
            .catch(error => {
                console.log(error)
            })
    }, []);

    const onForceChange = (value) => {
        console.log("Forces onForceChange", value)
        onForceSelected(value)
    };

    return <>
        <Form.Item name="forceId">
            <Select
                showSearch
                placeholder="Select Force"
                onChange={onForceChange}
                options={forceOptions}
                style={{width: 400}}
                disabled={true}
            />
        </Form.Item>
    </>
}

export default Forces;