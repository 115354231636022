import React, {useEffect, useState} from "react";
import {Menu} from "antd";
import { useNavigate } from "react-router-dom";
import {isAnonymous, isUserAuthenticated} from "../user/auth/Auth";
import {useCurrentUserContext} from "../user/CurrentUserContext";

const AnonymousUserMainMenu = () => {
    const navigate = useNavigate();

    const [current, setCurrent] = useState('home');
    const { currentUser } = useCurrentUserContext();

    const onClick = (e) => {
        setCurrent(e.key);
        navigate(e.key)
    };

    function buildItem(label, key, icon, children, type, style) {
        return {
            key,
            icon,
            children,
            label,
            type,
            style,
        };
    }

    const menuItems = [
        buildItem('Home', '/home', null, null, null, {float: 'left'}),
        buildItem('Sign In', '/user/login', null, null, null, {float: 'right'}),
    ];

    return (
        <>
            { isAnonymous(currentUser) && <Menu key="mainMenu" onClick={onClick} selectedKeys={[current]} mode="horizontal"  items={menuItems} style={{display: 'block'}}/> }
        </>
    );
};

export default AnonymousUserMainMenu;

