import React, {useEffect, useState} from 'react';
import {Form, List, Popover, Space, Table, Tag, Typography} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import TournamentTags from "./TournamentTags";


const Participants = ({tournamentId, tournamentPlayers}) => {

    const [players, setPlayers] = useState([])
    const [id, setId] = useState()
    const { Text, Link } = Typography;


    useEffect(() => {
        if (tournamentPlayers === undefined || tournamentPlayers === null || tournamentPlayers.length === 0) {

        } else {

            for (let i = 0; i < tournamentPlayers?.length; i++) {
                tournamentPlayers[i] = {
                    id: tournamentPlayers[i].id,
                    name: tournamentPlayers[i].username,
                    position: tournamentPlayers[i].position,
                    bscore: tournamentPlayers[i].stats?.bscore,
                    opponents: tournamentPlayers[i].stats?.opponents,
                    value: tournamentPlayers[i].id,
                    label: tournamentPlayers[i].username,
                    key: tournamentPlayers[i].key,
                    score: tournamentPlayers[i].stats?.score,
                    scoreDetails: tournamentPlayers[i].stats?.scoreDetails,
                    wins: tournamentPlayers[i].stats?.wins,
                    winDetails: tournamentPlayers[i].stats?.winDetails,
                    draws: tournamentPlayers[i].stats?.draws,
                    losses: tournamentPlayers[i].stats?.losses,
                    faction: tournamentPlayers[i].faction
                }
            }

            setPlayers(tournamentPlayers.sort((p1, p2) => p2.score - p1.score))
        }
    }, [tournamentPlayers]);

    const popoverGamesContent = (games) => (
        <>
            <List
                dataSource={games}
                renderItem={(item) => (
                    <List.Item>
                        {item}
                    </List.Item>
                )}
            />
        </>
    );

    const popoverBScoreContent = (score, opponents) => (
        <>
            <Space direction={"vertical"} >
                <Space direction={"horizontal"}>
                    Opponents Score: {score}
                </Space>
                <List
                    dataSource={opponents}
                    renderItem={(item) => (
                        <List.Item>
                            {item}
                        </List.Item>
                    )}
                />
            </Space>
        </>
    );

    const popoverFormulaContent = (formula) => (
        <>
           {formula}
        </>
    );

    const usersTableColumns = [
    {
        title: 'Position', dataIndex: 'position', key: 'position', width: '5%',
        render: (_,row) =>
            <>
                <Space direction={"horizontal"}>
                    {row.position}
                    {row.bscore !== undefined && row.bscore > 0 && <Popover content={popoverBScoreContent(row.bscore, row.opponents)} title="Details">
                        <InfoCircleOutlined  />
                    </Popover>
                    }
                </Space>
            </>
    },
    {
        title: 'Name', dataIndex: 'label', key: 'key', width: '40%',
        render: (_,row) =>
            <>
                <Space direction={"vertical"}>
                    {row.label}
                    <Space>
                        <TournamentTags tournamentId={tournamentId} participantId={row.id}  />
                    </Space>
                </Space>
            </>
    },{
        title: 'Faction', dataIndex: 'faction', key: 'faction', width: '30%',
    },{
        title: 'Score', dataIndex: 'score', key: 'key', width: '15%',
        render: (_,row) =>
            <>
                <Space direction={"horizontal"}>
                    {row.score}
                    <Popover content={popoverFormulaContent(row.scoreDetails)} title="Score Formula">
                        <InfoCircleOutlined  />
                    </Popover>
                </Space>
            </>
    },{
        title: 'Wins', dataIndex: 'wins', key: 'key', width: '5%',
        render: (_,row) =>
            <>
                <Space direction={"horizontal"}>
                    {row.wins}
                    {row.wins > 0 && <Popover content={popoverGamesContent(row.winDetails)} title="Win Games">
                        <InfoCircleOutlined />
                    </Popover>}
                </Space>
            </>
    },{
        title: 'Draws', dataIndex: 'draws', key: 'key', width: '5%',
    },{
        title: 'Losses', dataIndex: 'losses', key: 'key', width: '5%',
    }];

    return <>
        { (players.length === 0) &&
            <>
                <h2 className="tournTableInfoTitles">Participants:</h2>
                <div className="tournViewNoTable"> No Participants Found </div>
            </>
        }
        { (players.length > 0) && <Form.Item name="usersFormItem">
            <h2 className="tournTableInfoTitles">Participants:</h2>
            <div className="tournViewTable">
                <Table
                    onRow={(record, rowIndex) => {
                        return {
                            onMouseEnter: (event) => {
                                setId(record.id)
                            },
                            onMouseLeave: (event) => {
                                setId(undefined)
                            },
                        };
                    }}
                    columns={usersTableColumns}
                    dataSource={players}
                    pagination={
                        {
                            pageSize: 10
                        }
                    }
                    id={"usersTable"}
                    style={{width: '100%', minWidth: '42rem'}}
                    showHeader={true}
                />
            </div>
        </Form.Item>
        }

    </>
}

export default Participants;