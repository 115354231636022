import React, {useEffect, useState} from "react";
import {
    Button, Form, Input,
    Space,
    Table
} from "antd";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import {doRestCall} from "../../AppUtils";
import  "./styles.css"
import MarkdownEditor from "@uiw/react-markdown-editor";

const NewsCreate = () => {

    GoogleAnalyticsPageView("/news/create", "News Create")
    const navigate = useNavigate();

    const [form] = Form.useForm();

    function onFinish(news) {
        doRestCall( '/news/create', 'post', null, news)

        navigate("/news")
    }

    return (
        <>
            <Auth />


            <p>News Create</p>

            <Form id={"form"}
                  onFinish={onFinish}
                  form={form}
                  layout="horizontal"
            >

                <Form.Item
                    name="title"
                    label="Title"
                >
                    <Input   />
                </Form.Item>

                <Form.Item
                    name="body"
                    label="Body"
                >
                    <MarkdownEditor   />
                </Form.Item>

                <Button type="primary" htmlType="submit" data-cy="submit">
                    Submit
                </Button>

            </Form>

        </>
    );
};

export default NewsCreate;

