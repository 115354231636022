import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Tabs} from "antd";
import Games from "./Games";

const Rounds = ({ rounds, tournamentId }) => {

    const navigate = useNavigate();

    const [roundItems, setRoundItems] = useState([]);

    useEffect(() => {

        let temp = []
        for (let i = 0; i < rounds?.length; i++) {
            temp[i] = {
                key: rounds[i].key,
                label: 'Round ' + rounds[i].num,
                children: <Games games={rounds[i].games}  />
            }
        }
        setRoundItems(temp)

    }, [rounds])

    return <>
        <h2 className="tournTableInfoTitles">Rounds:</h2>
        <div className="tournViewTable">
            <Tabs
                defaultActiveKey={rounds?.length}
                items={roundItems}
            />
        </div>
    </>
}

export default Rounds;