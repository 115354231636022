import React, {useEffect, useState} from "react";
import {Cascader, InputNumber, Space, Table, Tag, Checkbox, Select, Divider, Typography, Button, Dropdown} from 'antd';
import Leader from "./Leader";
import "../styles.css"
import {findLeaders} from "../../RosterConnector";
import Duplicate from "./Duplicate";
import Warlord from "./Warlord";
import Prices from "./Prices";
import Weapons from "./Weapons";
import Count from "./Count";

const {Text, Link} = Typography;

const UnitSingleModel = ({unit, onUnitsChange, hideCustomize}) => {

    const [isWarlord, setWarlord] = useState(false);

    useEffect(() => {
        unit.warlord = isWarlord
        onUnitsChange()
    }, [isWarlord]);

    return (
        <>
            <Space direction="vertical">
                <Space>
                    {isWarlord && <Text type={"danger"}>WARLORD</Text>}
                    <Text strong className="rosterCreateParentName">{unit.name}</Text>
                    <Prices prices={unit.prices}/>
                    <Count model={unit.models[0]} onUnitsChange={onUnitsChange}/>
                    <Warlord unit={unit} setWarlord={setWarlord} hideCustomize={hideCustomize}/>
                    <Leader unit={unit} onUnitsChange={onUnitsChange} hideCustomize={hideCustomize} />
                </Space>
                <Space>
                    <Weapons model={unit.models[0]} onUnitsChange={onUnitsChange} hideCustomize={hideCustomize}/>
                </Space>
            </Space>
        </>
    );

};

export default UnitSingleModel;













