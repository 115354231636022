import React, {useEffect, useState} from "react";
import {
    Button,
    Table
} from "antd";
import {Auth} from "../../user/auth/Auth";
import {restUsage} from "../SystemConnector";
import moment from "moment";
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";

const RestUsage = () => {

    GoogleAnalyticsPageView("/restusage", "Rest Usage")

    const [loading, setLoading] = useState(true)
    const [usage, setUsage] = useState({});

    useEffect(() => {
        load()
    }, [])

    const load = () => {
        restUsage()
            .then(response => {
                setUsage(response)
                setLoading(false)

            })
            .catch(error => {
                console.log(error)
            })
    }


    const columns = [{
        title: 'createdAt', dataIndex: 'createdAt', key: 'createdAt',
        render: (_, row) => (
            moment(row.createdAt).format('DD MMM YYYY, hh:mm:ss.ms')
        )
    },{
        title: 'username', dataIndex: 'username', key: 'username',
    }, {
        title: 'endpoint', dataIndex: 'endpoint', key: 'endpoint',
    }, {
        title: 'details', dataIndex: 'details', key: 'details',
    },{
        title: 'request', dataIndex: 'request', key: 'request',
    },{
        title: 'response', dataIndex: 'response', key: 'response',
    }
    ];

    return (
        <>
            <Auth />
            {loading && <p>loading</p>}
            {!loading &&
                <Table
                    columns={columns}
                    dataSource={usage}
                    pagination={false}
                    id={"table"}
                    style={{width: '100%'}}
                    showHeader={true}
                />

            }
        </>
    );
};

export default RestUsage;

