import React, {useEffect, useState} from "react";
import {
    Button, Popconfirm,
    Space,
} from "antd";
import moment from "moment";
import {useNavigate, useParams} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import "./styles.css"
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import ChangeHistory from "./components/ChangeHistory";
import Settings from "./components/Settings";
import RosterValidation from "./components/RosterValidation";
import {useCurrentUserContext} from "../../user/CurrentUserContext";
import {doRestCall} from "../../AppUtils";

const GameView = () => {

    GoogleAnalyticsPageView("/game/view", "Game View")

    const navigate = useNavigate();

    let {gameId} = useParams();

    const {currentUser} = useCurrentUserContext();

    const [loading, setLoading] = useState(true)
    const [game, setGame] = useState({});

    useEffect(() => {
        load()
    }, [])

    function navigateBack() {
        navigate("/games")
    }

    function onGameViewSuccess(response) {
        setGame(response.body)
        setLoading(false)
    }

    const load = () => {
        doRestCall( '/game/view/' + gameId, 'get', null, null, onGameViewSuccess)
    }

    const onEdit = () => {
        navigate("/game/edit/" + gameId)
    };

    function onSuccess() {
        navigate('/games')
    }

    function onConfirmDelete() {
        doRestCall( '/game/delete/' + gameId, 'delete', null, null, onSuccess)
    }

    return (
        <>
            <Auth/>
            <h1 className="gameViewTitle">Game Details</h1>
            {loading && <p>loading</p>}
            {!loading &&
                <>
                    <div className="gameViewList">
                        <p>
                            <span className="gameListViewLabel">Name:</span>
                            <span data-cy="name" className="gameListViewValueBlack">{game.name}</span>
                        </p>
                        <p>
                            <span className="gameListViewLabel">Universe:</span>
                            {game.universe
                                ? <span className="gameListViewValueBlack">{game.universe}</span>
                                : <span className="gameListViewValueGrey">&#60;not set&#62;</span>
                            }
                        </p>
                        <p>
                            <span className="gameListViewLabel">Date:</span>
                            {
                                game.dateTime
                                    ? <span data-cy="dateTime" className="gameListViewValueBlack">
                                        {moment(game.dateTime, 'YYYYMMDD').format('DD MMM YYYY')}
                                    </span>
                                    : <span className="gameListViewValueGrey">&#60;not set&#62;</span>
                            }
                        </p>
                        <p>
                            <span className="gameListViewLabel">Game Master:</span>
                            {
                                game.gameMasterUsername
                                    ? <span  data-cy="gameMaster" className="gameListViewValueBlack">
                                            {game.gameMasterUsername}
                                        </span>
                                    : <span className="gameListViewValueGrey">&#60;not set&#62;</span>
                            }
                        </p>
                        <p>
                            <span className="gameListViewLabel">Notes:</span>
                            {
                                game.notes
                                    ? <span className="gameListViewValueBlack">{game.notes}</span>
                                    : <span className="gameListViewValueGrey">&#60;none&#62;</span>
                            }
                        </p>

                    </div>

                    <hr className="division"/>

                    {game.players && game.players.map((player) =>
                        <>
                            <div className="gameViewList">
                                <p>
                                    <span className="gameListViewLabel">Player:</span>
                                    {player?.username
                                        ? <span className="gameListViewValueBlack">{player.username}</span>
                                        : <span className="gameListViewValueGrey">&#60;not set&#62;</span>
                                    }
                                </p>
                                <p>
                                    <span className="gameListViewLabel">Player faction:</span>
                                    {player?.faction
                                        ? <span className="gameListViewValueBlack">{player.faction}</span>
                                        : <span className="gameListViewValueGrey">&#60;not set&#62;</span>
                                    }
                                </p>
                                <p>
                                    <span className="gameListViewLabel">Player score:</span>
                                    {player?.score
                                        ? <span className="gameListViewValueBlack">{player.score}</span>
                                        : <span className="gameListViewValueGrey">&#60;not set&#62;</span>
                                    }
                                </p>
                            </div>
                            <hr className="division"/>
                        </>
                    )}

                    <div className="gameViewList gameViewListLast">
                        <span className="gameListViewLabel">Winner:</span>
                        <span className="gameListViewValueBlack">{game.gameWinnerUsername}</span>
                        <ChangeHistory targetType={"GAME"} targetId={gameId}/>
                    </div>

                    <Space className="gameViewButtons" direction={"horizontal"}>
                        <Button onClick={navigateBack}>
                            Back
                        </Button>
                        <div>
                            {(currentUser.role === 'ADMIN' || currentUser.id === game.ownerId || game.gameMasterId)
                                && <Space direction={"horizontal"}>
                                    <Button onClick={onEdit}>Edit</Button>
                                    <Popconfirm title={"Delete game"} description={"Are you sure you want to delete this game?"}
                                        onConfirm={onConfirmDelete} >
                                         <Button>Delete</Button>
                                    </Popconfirm>
                                </Space>
                            }
                        </div>
                    </Space>
                </>
            }
        </>
    );
};

export default GameView;

