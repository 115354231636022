import axios from "axios";
import {getToken} from "../../user/auth/Auth";
import {restRoute} from "../../AppUtils";


export function deleteCard(forceId, cardId) {
    return axios
        .delete(restRoute() + '/crusade/force/' + forceId + '/card/' + cardId + '/delete', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function deleteForce(forceId) {
    return axios
        .delete(restRoute() + '/crusade/force/' + forceId +'/delete', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function deleteGameResult(forceId, gameResultId) {
    return axios
        .delete(restRoute() + '/crusade/force/' + forceId + '/gameresult/' + gameResultId + '/delete', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });

}

export function createForce(force) {
    return axios
        .post(restRoute() + '/crusade/force/create', force, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}


export function forceView(forceId) {
    return axios
        .get(restRoute() + '/crusade/force/' + forceId + '/view', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function myForces() {
    return axios
        .get(restRoute() + '/crusade/forces/my/created', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function updateName(forceId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/force/update/name', {}, {
            params: {
                forceId: forceId,
                name: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });
}


export function updateReqPoints(forceId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/force/update/reqpoints', {}, {
            params: {
                forceId: forceId,
                reqPoints: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });
}


export function updateVictories(forceId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/force/update/victories', {}, {
            params: {
                forceId: forceId,
                victories: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });
}


export function updateBattleTally(forceId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/force/update/battletally', {}, {
            params: {
                forceId: forceId,
                battleTally: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });
}


export function updateSupplyUsed(forceId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/force/update/supplyused', {}, {
            params: {
                forceId: forceId,
                supplyUsed: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });
}


export function updateSupplyLimit(forceId, value, onSuccess, onFailure) {
    return axios
        .put(restRoute() + '/crusade/force/update/supplylimit', {}, {
            params: {
                forceId: forceId,
                supplyLimit: value
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getToken().value,
            },
        })
        .then((res) => {
            onSuccess()
            return res.data;
        })
        .catch((error) => {
            onFailure()
            console.log(error);
        });
}

